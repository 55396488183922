import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkPermission, concatUrlWithId, notify } from '../utils/commonUtils';
import Documents from './DocumentManager/Documents';
import { ViewOnlyCurrencyField, ViewOnlyFormField, ViewOnlyDatePickerField } from './Forms/FormFields';
import OperatingBusinessYearModel from '../pages/operatingBusiness/OperatingBusinessYearModel';
import { DELETE_OPERATING_BUSINESS_YEAR } from '../api/endpoints';
import { remove } from '../api';
import { confirmAlert } from 'react-confirm-alert';

const DynamicTab = ({ businessID, data, tabKey }) => {
    const [dynamicData, setDynamicData] = useState([])
    const [selectedTab, setSelectedTab] = useState('');
    const [selectedTabData, setSelectedTabData] = useState({})
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('');
    const [yearArr, setYearArr] = useState([]);

    const handleTabChange = (tabName, tabValue, event) => {
        event.preventDefault();
        setSelectedTab(tabName);
        setSelectedTabData(tabValue);
    };

    useEffect(() => {
        if (data && data.length && data[0][tabKey]) {
            setDynamicData(data)
            setSelectedTab(data[0][tabKey]);
            setSelectedTabData(data[0]);
        }
    }, [data])

    const ChangeEditmode = ((id) => {
        let tempYearArr = [];
        dynamicData.forEach(obj => {
            if(id !== obj.id) {
                tempYearArr.push(obj[tabKey])
            }
        });
        setYearArr(tempYearArr)
        setShow(true);
        setMode('edit')
    });
    const handleClose = () => setShow(false);
    const handleDelete = async (id, name) => {
        try {
            const deleteBusinessYear = await remove(concatUrlWithId(DELETE_OPERATING_BUSINESS_YEAR, id));
            handleChange('delete', id);
            notify(`Operating Business Year ${name} successfully deleted`, 'success')
        } catch (error) {
            notify(`Unable to delete ${name}`, 'error')
            console.log('Error while deleting the error : ', error)

        }
    };

    const deleteBusinessYear = (year_id, year_name) => {
        confirmAlert({
            title: 'Delete',
            message: `Are you sure you want to delete the ${year_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(year_id, year_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    const handleChange = (type, dataValue) => {
        const updatedDynamicValue = dynamicData.length > 1 ? dynamicData.filter((obj) => {
            if (!((type === 'edit' && obj.id === dataValue.id) || (type === 'delete' && obj.id === dataValue))) {
                return obj;
            }
        }) : [];
        if (type === 'edit') {
            setDynamicData(updatedDynamicValue.length ? [dataValue].concat(updatedDynamicValue) : [dataValue])
            setSelectedTabData(dataValue);
            setSelectedTab(dataValue[tabKey]);
            setShow(!show);
        } else if (type === 'delete') {
            if (updatedDynamicValue && updatedDynamicValue.length) {
                setDynamicData(updatedDynamicValue);
                setSelectedTabData(updatedDynamicValue[0]);
                setSelectedTab(updatedDynamicValue[0][tabKey]);
            } else {
                setDynamicData([]);
            }
        }
    };
    return (
        (dynamicData && dynamicData.length) ?
            <>
                <div className='card'>
                    <div className='card-body'>
                        {/* Tab buttons */}
                        <ul className="nav nav-tabs">
                            {(dynamicData && dynamicData.length && dynamicData[0][tabKey]) && dynamicData.map((tab) => (
                                <li className="nav-item" key={tab.id} onClick={(e) => handleTabChange(tab[tabKey], tab, e)}>
                                    <a className={`nav-link ${selectedTab === tab[tabKey] ? 'active' : ''}`} href="#">{tab[tabKey]}</a>
                                </li>
                            ))}

                        </ul>
                    </div>
                    <div >
                        <div className="introduction-farm" style={{ paddingBottom: '10px' }}>
                            <div className="content-wraper-area">
                                <div className="dashboard-area">
                                    <div className="container-fluid">
                                        <div className="col-12">
                                            <div className="card" style={{ borderRadius: '4px', marginTop: '-14px' }}>
                                                <div className="card-body" style={{ backgroundColor: 'white' }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            {/* {(checkPermission(userPermissions, 'edit-stock') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&  */}
                                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Edit Year</Tooltip>} >
                                                                <i className="bx bxs-edit btn btn-circle" onClick={() => ChangeEditmode(selectedTabData?.id)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                            </OverlayTrigger>
                                                            {/* } */}
                                                            {/* {(checkPermission(userPermissions, 'delete-stock') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&  */}
                                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Delete Year</Tooltip>} >
                                                                <i className="bx bx-trash btn btn-circle" onClick={() => deleteBusinessYear(selectedTabData?.id, selectedTabData?.year)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#dd4a57', cursor: 'pointer' }}></i>
                                                            </OverlayTrigger>
                                                            {/* } */}
                                                        </div>
                                                    </div>
                                                    <div style={{ overflow: 'auto' }}>
                                                        {(selectedTabData && selectedTabData[tabKey]) &&
                                                            <fieldset>
                                                                <legend className='text-bold'> Year Details </legend>
                                                                <div className='row'>
                                                                    <div className='col-lg-6'>
                                                                        <ViewOnlyFormField fieldName="estimated_value" label="Estimate Value" value={selectedTabData.estimated_value} />
                                                                        <ViewOnlyFormField fieldName="number_of_employees" label="Number of Employees" value={selectedTabData.number_of_employees} />
                                                                        <ViewOnlyCurrencyField fieldName="total_assets" label="Total Assets" value={selectedTabData.total_assets} />
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <ViewOnlyDatePickerField fieldName="estimated_entry" label="Estimated Entry" value={selectedTabData.estimated_entry} />
                                                                        <ViewOnlyDatePickerField fieldName="employees_entry" label="Date of Entry" value={selectedTabData.employees_entry} />
                                                                        <ViewOnlyCurrencyField fieldName="total_liabilities" label="Total Liabilities" value={selectedTabData.total_liabilities} />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        }
                                                    </div>
                                                    <OperatingBusinessYearModel show={show} mode={mode} businessID={businessID} handleClose={handleClose} businessYearID={selectedTabData?.id} handleChange={handleChange} yearArr={yearArr} />

                                                    <div style={{ overflow: 'auto' }}>
                                                        {(selectedTabData && selectedTabData[tabKey]) ? <Documents oBYearId={selectedTabData.id} uploadPermission={true} isArchived={false} isOperatingBusiness={true} businessID={businessID} /> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> : ''
    )
};

export default DynamicTab;

