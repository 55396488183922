import React, { useState, useEffect, useContext } from 'react';
import Table from '../../components/Table/Datatable'
import { get, post, put, remove } from '../../api/index'
import { GET_ALL_OPERATING_BUSINESS, GET_OPERATING_BUSINESS, OPERATING_BUSINESS_DELETE, OPERATING_BUSINESS_ARCHIVE } from '../../api/endpoints'
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css'
import { checkPermission, concatUrlWithId, notify, refreshCount } from '../../utils/commonUtils';
import { PageLoader } from '../../Context/PageLoader';
import { useNavigate } from 'react-router-dom'
import ReminderTab from '../../components/Reminder/ReminderTab';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from '../../components/Footer';
import { UserPermissionStore } from '../../Store/UserPermission';


function OperatingBusiness() {

    const { loading, setLoading } = useContext(PageLoader);
    const [initialValues, setInitialValues] = useState({ business_name: '', primary_product_service: '', operating_business_id: '' });
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [selectedOpID, SetSelectedOpID] = useState('');
    const [enablePrint, SetEnablePrint] = useState(false);
    const [userPermissions, setUserPermissions] = useState("");
    const { checkAndUpdatePermissions } = UserPermissionStore();

    const history = useNavigate();
    const handleShowViewModal = async (id) => {
        const editData = await get(concatUrlWithId(GET_OPERATING_BUSINESS, id));
        setInitialValues({
            business_name: editData?.business_name,
            primary_product_service: editData?.primary_product_service,
            operating_business_id: editData?.id
        })
    }
    const viewOperatingBusiness = async (id) => {
        history(`/view-operating-business/${id}`);
    }

    // Reminder Task 
    const [showReminderTask, setShowReminderTask] = useState(false);
    const [getInvestmentID, setInvestmentID] = useState('');
    const reminderTaskPopUp = (investment_id) => {
        setInvestmentID(investment_id);
        setShowReminderTask(true);

    };

    const handleCloseReminderTask = () => setShowReminderTask(false);

    const handelListUpdate = () => getDocuments();


    const [documentList, setDocumentList] = useState([]);

    /* Upload Modal  */
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('add');
    const [editId, setEditId] = useState('add');
    const handleClose = () => setShow(false);
    const handleShow = (type = 'add') => {
        history('/add-operating-business')
    }
    const handleEdit = (id) => {
        handleShowViewModal(id);
        history('/edit-operating-business/' + id)
        // handleShow('edit');
        // setMode('edit')
        setEditId(id);
    }


    function deleteDocument(id, Name) {
        confirmAlert({
            title: 'Delete document',
            message: `Are you sure you want to delete the Operating Business ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id, Name)
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });
    }

    const handleDelete = async (id, name) => {
        try {
            const deleteInvestment = await remove(concatUrlWithId(OPERATING_BUSINESS_DELETE, id));
            if (deleteInvestment) {
                // history('/stock-list')
            }
            notify(`Operating Business successfully deleted`, 'success')
            refreshCount()
            getDocuments();
        } catch (error) {
            notify(`Unable to delete ${name}`, 'error')
            console.log('Error while deleting the error : ', error)
        }

    };

    const handleOnClickAction = (type, id, name, url) => {
        switch (type) {
            case 'view':
                viewOperatingBusiness(id);
                break;
            case 'edit':
                handleEdit(id);
                break;
            case 'delete':
                deleteDocument(id, name);
                break;
            case 'setreminder':
                handleReminderClick(id, name);
                break;
            case 'reminderTask':
                reminderTaskPopUp(id);
                break;
            case 'archive':
                archivedOperatingBusiness(id, name);
                break;
            default:
                break;
        }
    };

    const handleTableChange = (type, value) => {
        try {
            switch (type) {
                case 'print':
                    SetEnablePrint(value);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log('Error in the handle table change funciton:: ', error)
        }
    }

    const archivedOperatingBusiness = (investment_id, investment_name) => {
        confirmAlert({
            title: 'Archive',
            message: `Are you sure want to Archive ${investment_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleArchived(investment_id, investment_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const handleArchived = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const archive = await post(OPERATING_BUSINESS_ARCHIVE, { is_archived: true, id: investment_id });
            if (archive) {
                getDocuments()
                refreshCount();
            }
            notify(`${investment_name} has been archived.`, 'warning',)
        } catch (error) {
            console.log('Error while deleting the error : ', error)
            notify(`Unable to archive ${investment_name}.`, 'warning',)
        }
    };

    const handleReminderClick = (id, name) => {
        SetSelectedOpID(id)
        setShowReminderModel(!showReminderModel)
    };

    const handleReminderModelClose = () => {
        setShowReminderModel(false);
    };


    const onUploadSuccess = (isEdit, name) => {
        if (isEdit) {
            notify(`Document successfully updated`, 'success')
        } else {
            notify(`Document successfully uploaded`, 'success')
        }
        handleClose()
        getDocuments();
    }


    useEffect(() => {
        console.log('Coming inside the operating business ')
        setLoading(true);
        authCheckAndFetch();
    }, [])

    const authCheckAndFetch = async () => {
        let tempPermission = await checkAndUpdatePermissions();

        setUserPermissions(tempPermission || []);

        if (checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager') || checkPermission(tempPermission, 'view-operating-business')) {
            getDocuments();
        } else {
            notify(`Authentication is required to access this resource.`, 'error')
            history('/portfolio-summary')
            setLoading(false);
        }
    };

    const getDocuments = async () => {
        // if (uploadPermission) {
        try {
            let data = await get(GET_ALL_OPERATING_BUSINESS);
            setDocumentList(data?.data)
            setLoading(false);
        } catch (error) {
            console.log('Errro message', error?.response?.data?.message)
            if (error?.response?.data?.message) {
                notify(`${error?.response?.data?.message}`, 'error')
                history('/portfolio-summary')
            } else {
                notify(`${error}`, 'error')
                console.log('Error while deleting the error : ', error)
            }
            setLoading(false);
        }
    }

    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        {
            dataField: 'business_name', text: 'Legal Entity Name', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id'],
            headerWidth: '180px', wordWarp: true, isWarningIcon: true, url : '/view-operating-business', action: true
        },
        { dataField: 'primary_product_service', text: 'Primary Product/Service', sort: true },
        { dataField: 'status', text: 'Status', sort: true, isStatusField: true },
        { dataField: 'createdAt', text: 'Created Date', sort: true, isDateField: true },
        { dataField: 'updatedAt', text: 'Last Updated Date', sort: true, isDateField: true },
        { dataField: 'projected_annual_income', text: 'Cash Flow($)', sort: true, isAmountField: true },
    ];

    let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, tooltipSuffixText: ' ', handleOnClick: handleOnClickAction, onClickParams: ['id', 'business_name'], reminderCount: ['active_reminders_count'], setReminder: true }


    if (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) {
        columns.push(tempAction);
    } else {
        if (!checkPermission(userPermissions, 'edit-operating-business')) tempAction.disableEdit = true;
        if (!checkPermission(userPermissions, 'archive-operating-business')) tempAction.disableArchive = true;
        if (!checkPermission(userPermissions, 'delete-operating-business')) tempAction.disableDelete = true;
        tempAction = { ...tempAction, setReminder: true }
        columns.push(tempAction);
    };

    const updateReminderData = async () => {
        await getDocuments();
    }

    const handlePrintClick = () => {
        confirmAlert({
            title: 'Alert',
            message: `For better viewing, please use landscape mode.`,
            buttons: [
                {
                    label: 'Done',
                    onClick: () => { SetEnablePrint(true); setLoading(true); }
                },
            ]
        });
    }

    return (
        <div className="main-content introduction-farm">
            <div className="content-wraper-area">
                <div className="dashboard-area">
                    <div className="container-fluid">
                        <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
                        <br></br>
                        <div className='card'>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-6'>
                                        <legend className='text-bold'>Operating Business <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                            <i className="fa fa-print btn btn-circle print-btn" onClick={() => { handlePrintClick() }} ></i>
                                        </OverlayTrigger>
                                        </legend>
                                    </div>
                                    <div className='col-6'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                            {
                                                (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'create-operating-business')) &&
                                                <button type='button' onClick={handleShow} className='btn btn-primary'><i className="icon_plus"></i> Add New</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <OperatingBusinessModel show={show} mode={mode} handleClose={handleClose} /> */}

                                <Table data={documentList || []} columns={columns} disableCsv={true} disableSearch={true} enablePrint={enablePrint} handleTableChange={handleTableChange} headerName={'Operating Business'} />
                                {/* <ReminderList show={showReminderModel} recordId={selectedOpID} handleClose={handleReminderModelClose} entityType={'operating_business'} headerName={'Reminder'} invesmentCat={''} handelListUpdate={handelListUpdate} />
                                <ReminderTaskModel show={showReminderTask} investmentID={getInvestmentID} handleClose={handleCloseReminderTask} handelListUpdate={handelListUpdate} entityType={'operating_business'} /> */}
                                <ReminderTab show={showReminderTask} recordId={getInvestmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={handelListUpdate} invesmentCatId={''} entityType={'operating_business'}
                                    actionPermission={(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'edit-operating-business'))} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
        </div>
    );
}

export default OperatingBusiness;