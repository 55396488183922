
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '../../../../Context/PageLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { confirmAlert } from 'react-confirm-alert';
import { get, post, put, remove } from '../../../../api'
import { GET_ALL_INCOME_EXPENSE, DELETE_INCOME_EXPENSE } from '../../../../api/endpoints'
import { concatUrlWithId } from '../../../../utils/commonUtils';
import Datatable from '../../../../components/Table/Datatable';
import IncomeExpenseModelForm from '../incomeExpense/IncomeExpenseModelForm';


function IncomeExpenseList({ props, headerName, keyValue, createPermission, viewPermission, editPermission, deletePermission,
  isTabMode, hangleValueChange, actionPermissionPrefix, isAdminOrManager, permissionsArr, enablePrint = false, onIncExpComplete = () => { } }) {

  const [viewMode, setViewMode] = useState(true);
  const [investmentId, setInvestmentId] = useState('');
  const history = useNavigate();
  const location = useLocation();
  const { loading, setLoading } = useContext(PageLoader);
  const [incomeExpenseList, setIncomeExpenseList] = useState('');
  /* Upload Modal  */
  const [show, setShow] = useState(false);
  const [mode, setMode] = useState('add');
  const { investmentID } = useParams();
  const [editId, setEditId] = useState('add');
  const handleClose = () => {
    fetchIncomeExpenseList();
    setShow(false);
  }
  const handleShow = (type) => {

    setShow(true);
    setMode(type)
  }
  const handleAdd = () => {
    handleShow();
  }

  const handleEdit = (id) => {
    handleShow('edit');
    setEditId(id);
  }

  const onUploadSuccess = () => {
    handleClose()
    fetchIncomeExpenseList();
  }
  const notify = (message, type) => toast(message, { type });

  const fetchIncomeExpenseList = async () => {
    try {
      if (viewPermission) {
        let recordID;
        if (investmentID) {
          recordID = investmentID
        } else {
          console.error('No investment ID found');
          return;
        }
        const incomeExpenses = await get(concatUrlWithId(GET_ALL_INCOME_EXPENSE, `${recordID}/${keyValue}`));
        if (incomeExpenses) {
          let totalIncome = 0
          incomeExpenses.forEach(item => {
            const amount = parseFloat(item.amount);
            if (!isNaN(amount)) { // Check if amount is a valid number
              totalIncome += amount;
            }
          });
          hangleValueChange(keyValue === "INCOME" ? 'totalIncome' : 'totalExpense', parseFloat(totalIncome).toFixed(2))
          setIncomeExpenseList(incomeExpenses);
        }
        setViewMode(isTabMode)
      }
    } catch (error) {
      console.log('Error while fetching: ', error);
      setLoading(false);
    } finally {
    }
  };

  const onTableComplete = () => {
    onIncExpComplete(keyValue, true);
  }

  const handleOnClickAction = (type, id, name) => {
    switch (type) {
      case 'view':
        handleViewClick(id);
        break;
      case 'edit':
        handleEdit(id);
        break;
      case 'delete':
        deleteIncomeExpense(id, name);
        break;
      default:
        break;
    }
  };

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    {
      dataField: 'financial_activities', text: 'Financial Activity', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id', 'type'],
      headerWidth: '160px'
    },
    { dataField: 'frequency_type', text: 'Frequency', sort: true  },
    // { dataField: 'transaction_date', text: 'Transaction Date', sort: true, isDateField: true },
    { dataField: 'amount', text: 'Amount ($)', sort: true, isAmountField: true },
    { dataField: 'projected_amount', text: 'Projected Amount ($)', sort: true, isAmountField: true },
    { dataField: 'year_upto', text: 'Year Up To', sort: true },
    { dataField: 'income_expense_created_by.full_name', text: 'Created By', sort: true },
    // {
    //   dataField: 'comments', text: 'Comments', headerWidth: '180px', sort: true, formatter: (cell) => <div style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cell}</div>
    // }
  ];

  if (editPermission || deletePermission) {
    let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, tooltipSuffixText: "Income Expense", handleOnClick: handleOnClickAction, onClickParams: ['id', 'type'], disableArchive: true };
    if (!editPermission) tempAction.disableEdit = true;
    if (!deletePermission) tempAction.disableDelete = true;

    columns.push(tempAction);
  }


  const handleViewClick = (investmentId) => {
    handleShow('view');
    setEditId(investmentId);
  };

  const handleDeleteIncomeExpense = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      const deleteInvestment = await remove(concatUrlWithId(DELETE_INCOME_EXPENSE, investment_id) + '/' + investmentID);
      if (deleteInvestment) {
        history(`/view-real-estate-holdings/${investmentID}`, { state: { viewMode: true, investmentId: investmentId, viewAction: true, previousPage: "Real Estate Holdings List", previousUrl: "/real-estate-holding-list" } });
      }
      notify(`${investment_name} successfully deleted`, 'success')
      setLoading(false)
    } catch (error) {
      console.log('Error while deleting the error : ', error)
      setLoading(false)
    }

  };

  const deleteIncomeExpense = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Delete Income Expense',
      message: `Are you sure you want to delete the ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteIncomeExpense(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  useEffect(() => {
    // window.scrollTo(0, 0);
    fetchIncomeExpenseList();
    const fetchInvestmentById = async () => {
      // setLoading(true);
      if (location.state) {
        setViewMode(location.state.viewMode)
        setInvestmentId(location.state.investmentId)
      } else if(!enablePrint) {
        setLoading(false);
      }

    }
    fetchInvestmentById();
  }, [location.state]);

  return (

    <>

      {(viewMode) &&
        <div className="content-wraper-area" style={{ paddingBottom: '10px' }}>
          <div className="dashboard-area">
            <div className="container-fluid">
              <div className="col-12">
                <div className="card">
                  <div className="card-body tab-data-model" >
                    <div className="card-title" style={{ marginBottom: '5px' }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <h4 style={{ flex: 'auto' }}>{headerName} List</h4>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                          {viewPermission && createPermission && <button type='button' onClick={handleAdd} className='btn btn-primary no-print'>Add New</button>}
                        </div>
                        {!enablePrint && <IncomeExpenseModelForm show={show} mode={mode} editId={editId} handleClose={handleClose} onSuccess={onUploadSuccess} investmentId={investmentID} keyValue={keyValue} headerName={headerName} />}
                      </div>
                    </div>
                    <div style={{ overflow: 'auto' }}>
                      <Datatable data={incomeExpenseList || []} columns={columns} csvFileName={headerName} actionPermissionPrefix={actionPermissionPrefix} isAdminOrManager={isAdminOrManager} permissionsArr={permissionsArr} viewPrintMode={enablePrint} onTableComplete={onTableComplete} tableKeyType={keyValue}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

    </>
  );
}
export default IncomeExpenseList;