
import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Footer from "../../../components/Footer";
import { PageLoader } from '../../../Context/PageLoader';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Datatable from '../../../components/Table/Datatable';
import DataChart from '../../../components/DataChart';
import { get, put, remove } from '../../../api';
import { GET_INVESTMENTS, DELETE_INVESTMENT_BY_ID, ARCHIVE_INVESTMENT_BY_ID } from '../../../api/endpoints'
import { checkPermission, concatUrlWithId, refreshCount } from '../../../utils/commonUtils';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function StockList() {
  const [investmentList, setInvestmentList] = useState([]);
  const [selectedInvList, setSelectedInvList] = useState([]);
  const [selectedChartValue, setSelectedChartValue] = useState("");
  const [userPermissions, setUserPermissions] = useState("");
  const [showAction, setShowAction] = useState(false);
  const [showReminderModel, setShowReminderModel] = useState(false);
  const [selectedInvID, SetSelectedInvID] = useState('')
  const [enablePrint, SetEnablePrint] = useState(false)
  const location = useLocation();
  const locationData = location.state;
  const { loading, setLoading } = useContext(PageLoader);
  const history = useNavigate();
  const notify = (message, type) => toast(message, { type });
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const category_id = process.env.REACT_APP_INVESTMENT_STOCK_MASTER_ID;

  // Reminder Task 
  const [showReminderTask, setShowReminderTask] = useState(false);
  const [reminderActionPermission, setReminderActionPermission] = useState(false);
  const [getInvestmentID, setInvestmentID] = useState('');
  const reminderTaskPopUp = (investment_id, actionPermission) => {
    setInvestmentID(investment_id);
    setShowReminderTask(true);
    setReminderActionPermission(actionPermission)
  };
  const handleClose = () => setShowReminderTask(false);

  const handelListUpdate = () => fetchInvestmentList();

  const fetchInvestmentList = async () => {
    try {
      const investments = await get(GET_INVESTMENTS);
      if (investments) {
        let isAction = investments.some((inv) => inv.permission_keys?.length > 1);
        setShowAction(isAction);

        const filterData = investments.filter(value => value.current_value != null);
        setInvestmentList(filterData);
        setSelectedInvList(investments)
      }
      refreshCount();
    } catch (error) {
      console.log('Error while throwing:: ', error);
    } finally {
      setLoading(false);
    }
  }

  const authCheckAndFetch = async () => {
    let tempPermission = await checkAndUpdatePermissions();

    setUserPermissions(tempPermission || []);

    if (checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager') || checkPermission(tempPermission, 'view-stock')) {
      fetchInvestmentList()
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true)
    authCheckAndFetch();
  }, [locationData])

  const handleChartChange = (selectedValue) => {
    if (selectedChartValue == selectedValue) {
      setSelectedInvList(investmentList)
      setSelectedChartValue("")
    } else {
      let filterInvesmentList = investmentList.filter((item) => (selectedValue == item['asset_type']));
      setSelectedChartValue(selectedValue)
      setSelectedInvList(filterInvesmentList)
    }
  }

  const handleDelete = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      let deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, investment_id));

      if (deleteInvestment) {
        fetchInvestmentList()
      }
      notify(`${investment_name} successfully deleted`, 'success')
    } catch (error) {
      notify(`Unable to delete ${investment_name}`, 'warning')
      console.log('Error while deleteing the error : ', error)
      setLoading(false);
    }
  };

  const handleEditClick = (investmentId) => {
    setLoading(true);
    history(`/edit-liquid-asset/${investmentId}`);
  };
  const handleViewClick = (investmentId) => {
    setLoading(true);
    history(`/view-liquid-asset/${investmentId}`);
  };

  const AddStock = () => {
    setLoading(true);
    history('/add-liquid-asset', { state: { previousPage: "Liquid Asset List", previousUrl: "/liquid-asset-list" } });
  };

  const deleteStock = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Delete Liquid Asset',
      message: `Are you sure you want to delete the ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDelete(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const archivedStock = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Archive Liquid Asset',
      message: `Are you sure want to Archive ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleArchived(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const handleArchived = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true });
      if (archive) {
        fetchInvestmentList()
      }
      notify(`${investment_name} has been archived.`, 'warning',)
    } catch (error) {
      console.log('Error while deleteing the error : ', error)
      notify(`Unable to archive ${investment_name}.`, 'warning',)
    }
  };

  const handleOnClickAction = (type, id, name, actionPermission) => {
    switch (type) {
      case 'view':
        handleViewClick(id);
        break;
      case 'edit':
        handleEditClick(id);
        break;
      case 'archive':
        archivedStock(id, name);
        break;
      case 'delete':
        deleteStock(id, name);
        break;
      case 'reminderTask':
        reminderTaskPopUp(id, actionPermission);
        break;
      case 'setreminder':
        handleReminderClick(id, name, actionPermission);
        break;
      default:
        break;
    }
  };

  // Reminder Function Start -------
  const handleReminderClick = (id, name, actionPermission) => {
    // console.log('Aciton permission :::', actionPermission)
    SetSelectedInvID(id)
    setShowReminderModel(!showReminderModel)
    setReminderActionPermission(actionPermission)
  };

  const handleReminderModelClose = () => {
    setShowReminderModel(false);
  };

  const onReminderSuccess = () => {
    // console.log('onReminderSuccess :::::: ')
  }

  // Reminder Function End -------

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    {
      dataField: 'investment_name', text: 'Investment Name', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id'],
      headerWidth: '180px', wordWarp: true, action: true
    },
    { dataField: 'asset_type', text: 'Asset Type', sort: true },
    { dataField: 'first_activity_date', text: 'First Activity Date', sort: true, isDateField: true },
    // { dataField: 'bank_account', text: 'Bank Account', sort: true },
    // { dataField: 'amount_invested', text: 'Investment Value($)', sort: true, isAmountField: true },
    { dataField: 'current_value', text: 'Current Value ($)', sort: true, isAmountField: true },
    // { dataField: 'performance', text: 'Performance', sort: false, isPerformanceField: true },
    { dataField: 'interest_earning_rate', text: 'Interest Earnings (%)', sort: true, isAmountField: true },
    { dataField: 'projected_annual_income', text: 'Cash Flow($)', sort: true, isAmountField: true },
  ];
  let tempAction = { dataField: 'Action', text: 'Action', classes: 'action-column', csvExport: false, standardAction: true, hMinWidth: '150px', handleOnClick: handleOnClickAction, tooltipSuffixText: " ", onClickParams: ['id', 'investment_name'], reminderCount: ['active_reminders_count'], setReminder: true }

  if (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) {
    columns.push(tempAction);
  } else {
    // if (!checkPermission(userPermissions, 'edit-stock')) tempAction.disableEdit = true;
    // if (!checkPermission(userPermissions, 'archive-stock')) tempAction.disableArchive = true;
    // if (!checkPermission(userPermissions, 'delete-stock')) tempAction.disableDelete = true;
    tempAction = { ...tempAction, disableDelete: true, disableEdit: true, disableArchive: true, setReminder: true }
    columns.push(tempAction);
  };

  const handleBackClick = () => {
    history('/portfolio-summary')
  }

  const updateReminderData = async () => {
    await fetchInvestmentList();
  }

  const handleTableChange = (type, value) => {
    try {
      switch (type) {
        case  'print':
          SetEnablePrint(value);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error in the handle table change funciton:: ', error)
    }
  }

  const handlePrintClick = () => {
    confirmAlert({
      title: 'Alert',
      message: `For better viewing, please use landscape mode.`,
      buttons: [
        {
          label: 'Done',
          onClick: () => {SetEnablePrint(true); setLoading(true); }
        },
      ]
    }); 
  }

  return (
    <div className="main-content introduction-farm">
      <div className="col-12" style={{ padding: '5px 15px 15px 15px' }}>
        <div className="card">
          <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
          <div className="card-body card-breadcrumb">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <a href="#" onClick={() => handleBackClick()} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                Back</a>
              {/* <div className="page-title-right title-prefix" >Liquid Assets</div> */}
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick()}>Portfolio Summary
                  </a></li>
                  <li className="breadcrumb-item active" >Liquid Assets</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wraper-area">
        <div className="dashboard-area">
          <div className="container-fluid">
            <div className="row" style={{ alignItems: 'flex-end', alignItems: 'center' }} >

            </div>
            <DataChart
              header={"Liquid Assets"} data={investmentList} labelKey={'asset_type'}
              seriesKey={["current_value", "amount_invested"]} onChange={handleChartChange}
              seriesName={["Current Value", "Invested Value"]} type="bar"
            />
            <br />
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title" style={{ marginBottom: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <h4 style={{ flex: 'auto' }}>Liquid Asset List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                        <i className="fa fa-print btn btn-circle print-btn" onClick={() => {handlePrintClick()}} ></i>
                      </OverlayTrigger></h4>
                      {
                        (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager') || checkPermission(userPermissions, 'create-stock')) &&
                        <button type="button" onClick={AddStock} className="btn btn-primary mb-2 me-2"><i className="icon_plus"></i> Add New</button>
                      }
                    </div>
                  </div>
                  <div >
                    <Datatable data={selectedInvList} columns={columns} headerName={'Liquid Asset List'} pdfFile={'liquid_assest_list'} csvFileName={'Liquid Asset List'} actionPermissionPrefix={'stock'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} enablePrint={enablePrint} handleTableChange={handleTableChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ReminderTaskModel show={showReminderTask} handleClose={handleClose} investmentID={getInvestmentID} handelListUpdate={handelListUpdate} />

      <ReminderList show={showReminderModel} recordId={selectedInvID} handleClose={handleReminderModelClose} entityType={'investment'} headerName={'Reminder'} invesmentCat={category_id} onSuccess={onReminderSuccess} handelListUpdate={handelListUpdate} /> */}
      <ReminderTab show={showReminderTask} recordId={getInvestmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={handelListUpdate} invesmentCatId={category_id} entityType={'investment'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} actionPermission={reminderActionPermission} />

      {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
    </div>);
}

export default StockList;