import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from "../../../components/Footer";
import { Formik, Form } from 'formik';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '../../../Context/PageLoader';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { get, post, put, remove } from '../../../api'
import {
    CREATE_PERSONAL_ASSETS, GET_PERSONAL_ASSETS_BY_ID, UPDATE_PERSONAL_ASSETS_BY_ID, ARCHIVE_INVESTMENT_BY_ID,
    DELETE_INVESTMENT_BY_ID, GET_REMINDER_LIST
} from '../../../api/endpoints'
import { checkPermission, concatUrlWithId, notify, TitleTrim, ReminderTaskIcon, formatDateAndTime } from '../../../utils/commonUtils';
import { FormField, SelectField, CurrencyField, TextAreaField, DatePickerField, SystemInfo, ViewDiv } from '../../../components/Forms/FormFields';
import TabComponent from '../../../components/TabComponent';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ProfessionalContacts from '../../../components/ProfessionalContacts';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { useReactToPrint } from 'react-to-print';

export const personalAssetsSchema = Yup.object().shape({

    investment_name: Yup.string().label('Item').trim().required('Item is required'),
    property_type: Yup.string().label('Property Type').required('Property Type is required'),
    // manufacturer: Yup.string().label('Manufacturer').trim().required('Manufacturer is required'),
    // model: Yup.string().label('Model').trim().required('Model is required'),
    // location: Yup.string().label('Location').trim().required('Location is required'),
    // amount_invested: Yup.string().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Amount Invested is required'),
    current_value: Yup.string().trim().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Current Value is required'),
    // insured_value: Yup.string().trim().matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.').required('Insured Valuation is required'),
    // insured_date: Yup.date().label('Insured Date').required('Insured Date required'),
    // insurance_carrier: Yup.string().label('Insurance Carrier').trim().required('Insurance Carrier is required'),
    // insurance_policy_number: Yup.string().label('Insurance Policy Number').trim().required('Insurance Policy Number is required'),
});


function AddPersonalAssets({ props }) {

    const { investmentID } = useParams();
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const urlName = parts[1];

    const [initialValues, setInitialValues] = useState({
        // is_archived,
        investment_name: '',
        property_type: '',
        manufacturer: '',
        model: '',
        location: '',
        amount_invested: '',
        current_value: '',
        insured_value: '',
        insured_date: '',
        insurance_carrier: '',
        insurance_policy_number: '',
        is_active: true,
        fixed_income: false,
        investment_category: process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID,
        note: '',
        is_archived: '',
        amount_borrowed: ''
    });
    const [editMode, setEditMode] = useState(false);
    const [listEditMode, setListEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const history = useNavigate();
    const location = useLocation();
    const { loading, setLoading } = useContext(PageLoader);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [viewAction, setViewAction] = useState(true);
    const [previousPage, setPreviousPage] = useState('');
    const [previousUrl, setPreviousUrl] = useState('');
    const [userPermissions, setUserPermissions] = useState([]);
    const { checkAndUpdatePermissions } = UserPermissionStore();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [showReminderTask, setShowReminderTask] = useState(false);
    const [reminderTaskCount, setReminderTaskCount] = useState(0);
    const [printMode, setPrintMode] = useState(false);
    const formRef = useRef();
    const category_id = process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID;

    const [showErrorIcon, setShowErrorIcon] = useState(false);


    function ChangeEditmode(id) {
        setLoading(true);
        history(`/edit-personal-assets/${investmentID}`, { state: { previousPage: "Personal Assets View", previousUrl: `/view-personal-assets/${investmentID}` } });
    }

    function deletePersonalAssets(id, Name) {
        confirmAlert({
            title: 'Delete Personal Assets',
            message: `Are you sure you want to delete the ${Name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(id, Name)
                },
                {
                    label: 'No',
                    // onClick: () => alert('Click No')
                }
            ]
        });

    }

    const handleBackClick = (investmentId, pageUrl) => {
        if (pageUrl) {
            history(pageUrl);
        }
        else {
            history('/personal-assets-list');
        }
    };

    const handleDelete = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, investment_id));
            if (deleteInvestment) {
                history('/personal-assets-list')
            }
            notify(`${investment_name} successfully deleted`, 'success')
        } catch (error) {
            console.log('Error while deleting the error : ', error)
        }

    };


    const archivedPersonalAssets = (investment_id, investment_name, status = true) => {
        confirmAlert({
            title: 'Archive Personal Assets',
            message: `Are you sure want to ${status ? 'Archive' : 'Unarchive'} ${investment_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleArchived(investment_id, investment_name, status)
                },
                {
                    label: 'No',
                }
            ]
        });
    }


    const handleArchived = async (investment_id, investment_name) => {
        try {
            setLoading(true)
            const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true })

            if (archive) {
                history('/personal-assets-list')
            }
            notify(`${investment_name} has been archived.`, 'warning')
        } catch (error) {
            console.log('Error while deleteing the error : ', error)
            notify(`Failed to archive ${investment_name}.`, 'error');
        }

    };

    const authCheckAndUpdate = async () => {
        let tempPermission = await checkAndUpdatePermissions();
        setUserPermissions(tempPermission || []);
    };


    // Reminder change start

    const getReminderTaskCount = async () => {

        let payload = {
            investment_id: investmentID
        };
        const reminderTasks = await post(GET_REMINDER_LIST, payload);

        if (reminderTasks?.data?.reminderList?.length) {
            setReminderTaskCount(reminderTasks?.data?.reminderList?.length)
        } else {
            setReminderTaskCount(0)
        }
    }


    // Reminder change end 

    useEffect(() => {
        // window.scrollTo(0, 0);
        // Auth Check and Update
        authCheckAndUpdate();

        if (urlName == 'view-personal-assets') {
            setViewMode(true)
            setEditMode(false);
            setViewAction(true);
            setPreviousPage('Personal Assets List')
            setPreviousUrl('/personal-assets-list')
            // if((!checkPermission(userPermissions, 'isAdmin') && !checkPermission(userPermissions, 'isManager')) ){
            //     console.log('check=========',checkPermission(userPermissions, 'view-personal-asset'),userPermissions);
            //     if(!checkPermission(userPermissions, 'view-personal-asset') ){
            //         notify("Sorry, you do not have permission to access this.", 'warning')
            //         history('/portfolio-summary')
            //     }
            // }
        } else if (urlName == 'edit-personal-assets') {
            setViewMode(false)
            setEditMode(true)
            setViewAction(false)
            setPreviousPage('Personal Assets List')
            setPreviousUrl('/personal-assets-list')
            // if((!checkPermission(userPermissions, 'isAdmin') && !checkPermission(userPermissions, 'isManager')) ){
            //     if(!checkPermission(userPermissions, 'view-personal-asset') ){
            //         notify("Sorry, you do not have permission to access this1.", 'warning')
            //         history('/portfolio-summary')
            //     }else if(!checkPermission(userPermissions, 'edit-personal-asset') ){
            //         notify("Sorry, you do not have permission to access this.", 'warning')
            //         history('/stock-list')
            //     }
            // }
        }



        const fetchInvestmentById = async () => {
            setLoading(true);
            if (location.state) {
                setPreviousPage(location.state.previousPage)
                setPreviousUrl(location.state.previousUrl)
            } else {
                setLoading(false);
            }
            if ((urlName == 'edit-personal-assets') || (urlName == 'view-personal-assets')) {

                try {
                    setLoading(true);
                    const response = await get(concatUrlWithId(GET_PERSONAL_ASSETS_BY_ID, investmentID));
                    if (response) {
                        let values = {
                            ...response,
                            investment_id: response.id,
                            property_type: response['personal_assets_details.type'],
                            manufacturer: response['personal_assets_details.Manufacturer'],
                            model: response['personal_assets_details.Model'],
                            location: response['personal_assets_details.location'],
                            amount_invested: response['personal_assets_details.insured_value'],
                            current_value: response['current_value'],
                            insured_value: response['personal_assets_details.insured_value'],
                            insured_date: formatDateAndTime(response['personal_assets_details.insured_date'],'YYYY-MM-DD'),
                            insurance_carrier: response['personal_assets_details.insurance_carrier'],
                            insurance_policy_number: response['personal_assets_details.insurance_policy_number'],
                            auditLog: response?.investmentAuditLog?.investment_headers?.current_value,
                            investmentAuditLog: response?.investmentAuditLog?.investment_headers?.amount_invested,


                        };

                        setContacts(response.professional_contacts);
                        setInitialValues(values);
                        getReminderTaskCount()
                        if (response.permission_keys?.length && response.permission_keys?.[0]?.permission_keys) {
                            setUserPermissions(response.permission_keys[0].permission_keys)
                        }

                        if (response.is_archived == true) {
                            setViewAction(false)
                            setPreviousPage('Archived List')
                            setPreviousUrl('/archived-list')
                        }
                    }
                } catch (error) {
                    window.scrollTo(0, 1);
                    console.log('Error while throwing:: ', error);
                    notify(error?.response?.data?.message, 'error');
                } finally {
                    window.scrollTo(0, 1);
                    setLoading(false);
                }
            } else {
                window.scrollTo(0, 1);
                setLoading(false);
            }
        }
        fetchInvestmentById();

    }, [urlName]);

    const propertyTypes = [
        { value: 'Art', label: 'Art' },
        { value: 'Airplane', label: 'Airplane' },
        { value: 'Bikes', label: 'Bikes' },
        { value: 'Helicopter', label: 'Helicopter' },
        { value: 'Precious Metals and Stones', label: 'Precious Metals and Stones' },
        { value: 'Vehicles and Equipment', label: 'Vehicles and Equipment' },
        { value: 'Yacht', label: 'Yacht' },
        { value: 'Other', label: 'Other' },
    ];

    const handleCollectedContacts = (updatedContacts) => {
        setContacts(updatedContacts);
    };

    const updateReminderData = async () => {
        await getReminderTaskCount();
    }

    const handleBeforePrint = () => {
        setPrintMode(true);
    };

    const handleAfterPrint = () => {
        setPrintMode(false);
        setLoading(false);
    };

    const handlePrint = useReactToPrint({
        content: () => formRef.current,
        onBeforeGetContent: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        documentTitle: initialValues.investment_name || 'investment'
    });

    const handleChangePrint = () => {
        confirmAlert({
            title: 'Alert',
            message: `For better viewing, please use landscape mode.`,
            buttons: [
                {
                    label: 'Done',
                    onClick: () => { setPrintMode(true); setLoading(true) }
                },
            ]
        });
    }

    const onDataLoadingComplete = () => {
        setLoading(false);
    }

    useEffect(() => {
        if (printMode) {
            setTimeout(() => {
                handlePrint()
            }, 100);
        };
    }, [printMode])

    const checkValidation = () => {
        setShowErrorIcon(true);
    }

    return (

        <>
            <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>

            <div className="container-fluid" style={{ marginTop: '20px' }}>

                <div className="col-12" style={{ padding: '15px' }}>
                    <div className="card">
                        <div className="card-body card-breadcrumb">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                                    Back</a>
                                {<div className="page-title-right title-prefix" >
                                    {`${editMode ? 'Edit -' : (viewMode ? 'View -' : '')}`}  <TitleTrim title={initialValues.investment_name} />
                                </div>}
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick(initialValues?.id, previousUrl)}>{previousPage}
                                        </a></li>
                                        <li className="breadcrumb-item active" >{editMode ? 'Edit Personal Assets' : (viewMode ? 'View Personal Assets' : 'Add Personal Assets')}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div ref={formRef}>
                {((editMode && initialValues?.id) || (viewMode && initialValues?.id) || !(viewMode || editMode)) && <Formik

                    initialValues={initialValues}
                    validationSchema={personalAssetsSchema}
                    onSubmit={async (values, actions) => {
                        setIsSubmitting(true);
                        try {
                            let response = []
                            const url = editMode ? concatUrlWithId(UPDATE_PERSONAL_ASSETS_BY_ID, investmentID) : CREATE_PERSONAL_ASSETS;
                            const bodyPayload = {
                                investment_name: values.investment_name,
                                investment_category: values.investment_category,
                                type: values.property_type,
                                Manufacturer: values.manufacturer,
                                Model: values.model,
                                location: values.location,
                                amount_invested: values.amount_invested,
                                current_value: values.current_value,
                                insured_value: values.insured_value,
                                insured_date: values.insured_date,
                                insurance_carrier: values.insurance_carrier,
                                insurance_policy_number: values.insurance_policy_number,
                                note: values.note,
                                fixed_income: values.fixed_income,
                                professional_contacts: contacts,
                                amount_borrowed: values.amount_borrowed,
                            }
                            response = editMode ? await put(url, bodyPayload) : await post(url, bodyPayload);

                            if (response) {

                                notify(`Personal Assets ${values.investment_name} ${editMode ? "Updated" : "Added"} Successfully...`, 'success')

                                if (isSaveNew == true) {
                                    actions.resetForm();
                                    setIsFieldClear((prevState) => prevState ? prevState + 1 : 1);
                                    setShowErrorIcon(false)
                                    setContacts([]);
                                } else {

                                    if (previousUrl) {
                                        history(previousUrl);
                                    } else {
                                        history('/personal-assets-list', { state: { status: editMode ? "update" : "create" } });
                                        setLoading(true);
                                    }
                                }

                            } else {
                                editMode ? notify(`Personal Assets update failed...`, 'error') : notify(`Create Stock failed...`, 'error');
                            }
                        } catch (error) {
                            notify(error?.response?.data?.message, 'error');

                            console.error('API Error:', error);
                        }
                        setIsSubmitting(false);
                    }}
                >
                    {props => (<div className="main-content introduction-farm">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid">
                                    <div className="card">
                                        <div className={`card-body ${viewMode ? 'view-mode bg-white' : 'add-mode'}`}>
                                            {!viewAction && viewMode && <div className='no-print' style={{ textAlign: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                        <i className="fa fa-print btn-circle print-btn" onClick={() => { handleChangePrint() }} ></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            }
                                            {viewAction && viewMode && <div style={{ textAlign: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                                                        <i className="fa fa-print btn-circle print-btn no-print" onClick={() => { handleChangePrint() }} ></i>
                                                    </OverlayTrigger>

                                                    {<ReminderTaskIcon
                                                        iconClass="no-print"
                                                        tooltip={`Set/View Reminders `}
                                                        onClick={() => setShowReminderTask(true)}
                                                        backgroundColor="#8098ad"
                                                        count={reminderTaskCount}
                                                    />}
                                                    {/* {<OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Set Reminder</Tooltip>} >
                                                    <i className="zmdi zmdi-alarm-plus btn btn-circle" onClick={() => setShowReminderModel(true)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#359bff', cursor: 'pointer' }} ></i>
                                                </OverlayTrigger>} */}
                                                    {(checkPermission(userPermissions, 'edit-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Edit Personal Assets</Tooltip>} >
                                                        <i className="bx bxs-edit btn btn-circle no-print" onClick={() => ChangeEditmode(initialValues?.id)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#58ae25', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>}
                                                    {(checkPermission(userPermissions, 'archive-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Archive Personal Assets</Tooltip>} >
                                                        <i className="zmdi zmdi-archive btn btn-circle no-print" onClick={() => archivedPersonalAssets(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#74788d', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>}
                                                    {(checkPermission(userPermissions, 'delete-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Delete Personal Assets</Tooltip>} >
                                                        <i className="bx bx-trash btn btn-circle no-print" onClick={() => deletePersonalAssets(initialValues?.id, initialValues?.investment_name)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#dd4a57', cursor: 'pointer' }}></i>
                                                    </OverlayTrigger>}
                                                </div>
                                            </div>
                                            }
                                            {/* {!viewAction && viewMode && <div style={{ textAlign: 'center' }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {
                                                    (checkPermission(userPermissions, 'archive-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) && <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Unarchive Personal Assets</Tooltip>} >
                                                        <i className="zmdi zmdi-open-in-browser btn btn-circle" onClick={() => archivedPersonalAssets(initialValues?.id, initialValues?.investment_name,false)} style={{ fontSize: '19px', margin: '0px 5px', color: 'white', background: '#74788d', cursor: 'pointer' }} ></i>
                                                    </OverlayTrigger>}
                                            </div>
                                        </div>
                                        } */}

                                            <Form>
                                                {printMode && <div className="card-title only-visible-in-print" style={{ marginBottom: '5px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                        <h4 className='heading-print text-bold' style={{ wordWrap: 'break-word', maxWidth: '300px' }}>Personal Assets - {initialValues.investment_name}</h4>
                                                    </div>
                                                </div>}
                                                <div className='row print-font-header'>
                                                    {!viewMode && <div className='col-lg-6'>
                                                        <fieldset>
                                                            <legend className='text-bold'> Personal Assets Information </legend>

                                                            <FormField viewMode={viewMode} fieldName="investment_name" label="Item" value={initialValues.investment_name} maxLength={200} />

                                                            <SelectField viewMode={viewMode} fieldName="property_type" label="Property Type" value={initialValues.property_type} options={propertyTypes} values={props.values} setFieldValue={props.setFieldValue} />

                                                            <FormField viewMode={viewMode} fieldName="manufacturer" label="Manufacturer" value={initialValues.manufacturer} maxLength={200} isRequired={false} />

                                                            <FormField viewMode={viewMode} fieldName="model" label="Model" value={initialValues.model} maxLength={200} isRequired={false} />

                                                            <FormField viewMode={viewMode} fieldName="location" label="Location" value={initialValues.location} maxLength={200} isRequired={false} />

                                                            <FormField viewMode={viewMode} fieldName="insurance_carrier" label="Insurance Carrier" value={initialValues.insurance_carrier} maxLength={200} isRequired={false} />
                                                        </fieldset>
                                                    </div>}

                                                    {!viewMode && <div className='col-lg-6'>
                                                        <fieldset>
                                                            <legend className='text-bold'> Investment Details </legend>

                                                            <CurrencyField viewMode={viewMode} fieldName="amount_invested" label="Amount Invested" values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.investmentAuditLog ? true : false} auditValue={initialValues.investmentAuditLog} isRequired={false} />

                                                            <CurrencyField viewMode={viewMode} fieldName="current_value" label="Current Value" values={props.values} setFieldValue={props.setFieldValue} auditLog={initialValues.auditLog ? true : false} auditValue={initialValues.auditLog} />

                                                            <CurrencyField viewMode={viewMode} fieldName="amount_borrowed" label="Amount Borrowed" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />

                                                            <CurrencyField viewMode={viewMode} fieldName="insured_value" label="Insured Value" values={props.values} setFieldValue={props.setFieldValue} isRequired={false} />

                                                            <DatePickerField viewMode={viewMode} fieldName="insured_date" label="Insured Date" value={initialValues.insured_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} isRequired={false} />

                                                            <FormField viewMode={viewMode} fieldName="insurance_policy_number" label="Insurance Policy Number" value={initialValues.insurance_policy_number} maxLength={200} isRequired={false} />

                                                        </fieldset>
                                                    </div>}

                                                    {!viewMode && <div className="col-lg-12 col-md-12 col-12">
                                                        <fieldset>
                                                            <legend className='text-bold'> Additional Information </legend>
                                                            <TextAreaField viewMode={viewMode} fieldName="note" label="Notes" value={initialValues.note} maxLength={500} textAlign={true} />
                                                        </fieldset>
                                                    </div>}

                                                    {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                        <div>
                                                            <legend className='text-bold lenged-view'> Personal Assets Information </legend>
                                                            <ViewDiv fieldLabel={"Item"} fieldValue={initialValues.investment_name} type={"text"} />
                                                            <ViewDiv fieldLabel={"Property Type"} fieldValue={initialValues.property_type} type={"text"} />
                                                            <ViewDiv fieldLabel={"Manufacturer"} fieldValue={initialValues.manufacturer} type={"text"} />
                                                            <ViewDiv fieldLabel={"Model"} fieldValue={initialValues.model} type={"text"} />
                                                            <ViewDiv fieldLabel={"Location"} fieldValue={initialValues.Location} type={"text"} />
                                                            <ViewDiv fieldLabel={"Insurance Carrier"} fieldValue={initialValues.insurance_carrier} type={"text"} />
                                                            <legend className='text-bold lenged-view'> System Information</legend>
                                                            <ViewDiv fieldLabel={"Created At"} fieldValue={initialValues.createdAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                            <ViewDiv fieldLabel={"Last Modified At"} fieldValue={initialValues.updatedAt} type={"date"} dateFormat={'MM/DD/YYYY hh:mm a'} />
                                                            <ViewDiv fieldLabel={"Created By"} fieldValue={initialValues['investment_headers_created_by.full_name']} type={"text"} />
                                                            <ViewDiv fieldLabel={"Last Modified By"} fieldValue={initialValues['investment_headers_modified_by.full_name']} type={"text"} />
                                                            <legend className='text-bold lenged-view'> Additional Information </legend>
                                                            <ViewDiv fieldLabel={"Notes"} fieldValue={initialValues.note} type={"text-area"} />


                                                        </div>
                                                    </div>}

                                                    {viewMode && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                                        <div>
                                                            <legend className='text-bold lenged-view'> Investment Details </legend>
                                                            <ViewDiv fieldLabel={"Amount Invested($)"} fieldValue={initialValues.amount_invested} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Market Value($)"} fieldValue={initialValues.current_value} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Insured Value($)"} fieldValue={initialValues.insured_value} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Amount Invested($)"} fieldValue={initialValues.amount_invested} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Market Value($)"} fieldValue={initialValues.current_value} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Amount Borrowed($)"} fieldValue={initialValues.amount_borrowed} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Insured Value($)"} fieldValue={initialValues.insured_value} type={"currency"} />
                                                            <ViewDiv fieldLabel={"Insured Date"} fieldValue={initialValues.insured_date} type={"text"} />
                                                            <ViewDiv fieldLabel={"Insurance Policy Number"} fieldValue={initialValues.insurance_policy_number} type={"text"} />
                                                            {!printMode && <>
                                                                <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                                <div className="mb-2">
                                                                    <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                                </div>
                                                            </>}
                                                        </div>
                                                    </div>}

                                                    {!viewMode && <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                        <fieldset>
                                                            <legend className="text-bold">Professional Contacts</legend>
                                                            <div className="mb-2">
                                                                <ProfessionalContacts contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                            </div>
                                                        </fieldset>
                                                    </div>}
                                                </div>

                                                {viewMode && <>
                                                    <div className='only-visible-in-print'>
                                                        <legend className="text-bold lenged-view">Professional Contacts</legend>
                                                        <div className="mb-2">
                                                            <ProfessionalContacts viewAction={viewMode} contactList={contacts} CollectedContact={handleCollectedContacts} />
                                                        </div>

                                                    </div>
                                                </>}
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            gap: '10px'
                                                        }}>
                                                            {!viewMode && showErrorIcon && !props.isValid && <><OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Please find the page error</Tooltip>} >
                                                                <i className="zmdi zmdi-block-alt" style={{ fontSize: '19px', margin: '0px 5px', padding: '8px 0px 0px 0px', color: 'red', cursor: 'pointer' }} ></i>
                                                            </OverlayTrigger></>}
                                                            {!viewMode && <button type="reset" onClick={() => handleBackClick(initialValues?.id, previousUrl)} className="btn btn-secondary w-md">{viewMode ? 'Back' : 'Cancel'}</button>}
                                                            {!viewMode && <button type="submit" onClick={() => { setIsSaveNew(false); checkValidation(); }} disabled={isSubmitting} className="btn btn-primary w-md">{editMode ? 'Update' : 'Save'}</button>}
                                                            {editMode != true && (!viewMode && <button type="submit" onClick={() => { setIsSaveNew(true); checkValidation(); }} className="btn btn-primary w-md">{editMode ? 'create' : 'Save and New'}</button>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                            {/* <div className='row'>
                                            {viewMode && <Documents investmentId={initialValues?.id}
                                                uploadPermission={checkPermission(userPermissions, 'upload-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')}
                                            />}
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            {viewMode && userPermissions?.length && !printMode && <TabComponent investmentId={initialValues?.id} permissions={userPermissions} viewAction={viewAction}
                                uploadPermission={checkPermission(userPermissions, 'upload-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} isArchived={!viewAction} documentTypeKey={'Personal Assets'} />}
                        </div>
                        {(userPermissions?.length && checkPermission(userPermissions, 'isManager') && (editMode || viewMode)) && <Footer PropUserPermissions={userPermissions} />}
                    </div>
                    )}
                </Formik >
                }
            </div>

            {/* <ReminderTaskModel show={showReminderTask} handleClose={() => setShowReminderTask(false)} investmentID={investmentID} handelListUpdate={() => getReminderTaskCount()} />
            <ReminderList show={showReminderModel} recordId={investmentID} handleClose={setShowReminderModel} entityType={'investment'} headerName={'Reminder'} invesmentCat={category_id} handelListUpdate={getReminderTaskCount} /> */}
            <ReminderTab show={showReminderTask} recordId={investmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={getReminderTaskCount}
                invesmentCatId={category_id} entityType={'investment'} isAdminOrManager={(checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager'))} actionPermission={(checkPermission(userPermissions, 'edit-personal-asset'))} />

        </>
    );
}
export default AddPersonalAssets;