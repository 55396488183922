import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import CryptoJS from 'crypto-js';
import { get } from '../api';
import { GET_DOCUMENT_TYPE, GET_MY_PERMISSIONS } from '../api/endpoints';

const secretKey = process.env.REACT_APP_USER_PERMISSION_KEY;

const encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const fetchPermission = async () => {
    try {
        const userPermission = await get(GET_MY_PERMISSIONS);
        if (userPermission && (userPermission.userPermission?.length || userPermission.userInvPermission?.length || userPermission.isAdmin || userPermission.isManager)) {
            let permissionArr = (userPermission.userPermission || []).flatMap(item => item['permission.permission_key'] || []);
            let userInvPermission = (userPermission.userInvPermission || []).flatMap(item => item['permission.permission_key'] || []);
            if (userInvPermission.length > 0) permissionArr.push(...userInvPermission);
            if (userPermission.isAdmin) permissionArr.push('isAdmin');
            if (userPermission.isManager) permissionArr.push('isManager');
            permissionArr = [...new Set(permissionArr)];
            return permissionArr;
        }
    } catch (error) {
        console.error('Error fetching user permissions:', error);
        return [];
    }
};


export const UserPermissionStore = create(
    persist(
        (set, get) => ({
            permissions: [],
            expirationTime: null,
            clearPermissions: () => set({ permissions: [], expirationTime: null }),
            checkAndUpdatePermissions: async () => {
                const { permissions, expirationTime } = get();
                if (!permissions || !permissions.length || !expirationTime || (Date.now() > expirationTime)) {
                    // Permissions are missing or expired, fetch new data
                    const newPermissions = await fetchPermission(); // Replace with your API call
                    // set({ permissions: newPermissions, expirationTime: Date.now() + 30 * 60 * 1000 });
                    set({ permissions: newPermissions, expirationTime: Date.now() + 1 * 60 * 1000 });
                    return newPermissions
                }
                return permissions
            },
        }),
        {
            name: 'user-permission', // Unique name for local storage
            getStorage: () => localStorage, // Use local storage
            serialize: (state) => encrypt(state), // Encrypt state before storing
            deserialize: (state) => decrypt(state), // Decrypt state after retrieving
        }
    )
);

const fetchDocumentTypes = async () => {
    try {
        const documentTypes = await get(GET_DOCUMENT_TYPE);
        if (documentTypes) {
            return documentTypes;
        }
    } catch (error) {
        console.error('Error fetching document types:', error);
        return [];
    }
}


export const DocumentTypeStore = create(
    persist(
        (set, get) => ({
            documentTypes: [],
            clearDocumentTypes: () => set({ documentTypes: [] }),
            checkAndUpdateDocumentTypes: async () => {
                const { documentTypes } = get();
                if (!documentTypes.length) {
                    // Permissions are missing or expired, fetch new data
                    const newDocumentTypes = await fetchDocumentTypes(); // Replace with your API call
                    set({ documentTypes: newDocumentTypes });
                    return newDocumentTypes
                }
                return documentTypes
            },
        }),
        {
            name: 'document-types', // Unique name for local storage
            getStorage: () => localStorage, // Use local storage
            serialize: (state) => encrypt(state), // Encrypt state before storing
            deserialize: (state) => decrypt(state), // Decrypt state after retrieving
        }
    )
)
