import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { get, post, put, remove } from '../../../../api'
import { CREATE_INCOME_EXPENSE, GET_INCOME_EXPENSE, UPDATE_INCOME_EXPENSE } from '../../../../api/endpoints'
import { CurrencyField, TextAreaField, DatePickerField, FormField, SelectField } from '../../../../components/Forms/FormFields';
import { Formik, Form } from 'formik';
import { concatUrlWithId, formatDateAndTime } from '../../../../utils/commonUtils';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import moment from 'moment';

export const investmentSchema = Yup.object().shape({
    amount: Yup.string()
        .matches(/^\d{1,13}(\.\d{1,2})?$/, 'Invalid number format. Use up to 13 digits before the decimal and up to 2 digits after.')
        .required('Amount is required'),
    financial_activities: Yup.string().label('Financial Activity').trim().required('Financial Activity is required'),
    frequency_type: Yup.string().label('Frequency').trim().required('Frequency is required'),
    frequency_start: Yup.string().when('frequency_type', {
        is: 'weekly',
        then: () => Yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, 'Frequency Start is required.').required('Frequency Start is required'),
        otherwise: () => Yup.string().when('frequency_type', {
            is: 'monthly',
            then: () => Yup.string().matches(/^\d{4}-\d{2}$/, 'Frequency Start is required.').required('Frequency Start is required'),
            otherwise: () => Yup.string().required('Frequency Start is required'),
        }),
    }).required('Frequency Start is required'),
    transaction_date: Yup.string().when('frequency_type', {
        is: 'specific',
        then: () => Yup.string().matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format. Use YYYY-MM-DD.').required('Transaction Date is required'),
        otherwise: () => Yup.string().nullable(),
    }),
    year_upto: Yup.string().when('frequency_type', {
        is: 'specific',
        then: () => Yup.string().nullable(),
        otherwise: () => Yup.date().required('Year Up To is required'),
    })
});

function IncomeExpenseModelForm({ show, handleClose, mode, editId, onSuccess, investmentId, keyValue, headerName, formAction }) {

    const [initialValues, setInitialValues] = useState({
        type: '',
        amount: '',
        transaction_date: '',
        comments: '',
        financial_activities: '',
        investment_id: investmentId,
        frequency_type: '',
        frequency_start: '',
        frequency_start_label: '',
        projected_amount: '',
        year_upto: '',
        created_year: ''
    });

    const d = new Date();
    let currentYear = d.getFullYear();

    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [isFieldClear, setIsFieldClear] = useState(false);
    const [isSaveNew, setIsSaveNew] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [enableSelect, setEnableSelect] = useState(false);
    const [enableDatePicker, setEnableDatePicker] = useState(false);
    const [enableOneTimePicker, setEnableOneTimePicker] = useState(false);
    const [enableWeeklyDatePicker, setEnableWeeklyDatePicker] = useState(false);
    const [enableYearDatePicker, setEnableYearDatePicker] = useState(false);
    const [optionArr, setOptionArr] = useState([]);

    const notify = (message, type) => toast(message, { type });

    const resetValues = () => {
        setInitialValues({
            type: '',
            amount: '',
            transaction_date: '',
            comments: '',
            financial_activities: '',
            investment_id: investmentId,
            frequency_type: '',
            frequency_start: '',
            frequency_start_label: '',
            projected_amount: '',
            year_upto: '',
            created_year: ''
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            resetValues();
            if (!mode) {
                resetValues()
                setEditMode(false);
                setViewMode(false);
                setIsFieldClear(true);
                setEnableDatePicker(false);
                setEnableOneTimePicker(false);
                setEnableWeeklyDatePicker(false);
                setEnableYearDatePicker(false);
            } else if (mode == 'view') {
                setViewMode(true);
            } else if (mode == 'edit') {
                setEditMode(true);
                setViewMode(false);
                setIsFieldClear(true);
            } else {
                setEditMode(false);
                setViewMode(false);
                setIsFieldClear(true);
                setEnableDatePicker(false);
                setEnableOneTimePicker(false);
                setEnableWeeklyDatePicker(false);
                setEnableYearDatePicker(false)
            }

            if ((mode == 'view' || mode == 'edit')) {
                try {
                    const response = await get(concatUrlWithId(GET_INCOME_EXPENSE, editId));
                    // Process the fetched data here
                    if (response) {
                        let values = {
                            ...response,
                            transaction_date: formatDateAndTime(response.transaction_date,'YYYY-MM-DD'),
                            investment_id: response.investment_id,
                            frequency_start_label: '',
                        };
                        setInitialValues(values);
                        handleFrequencyChange(response.frequency_type)

                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }

            } else {
                resetValues();
            }
        };

        fetchData();
    }, [show, mode, editId]);

    const frequencyOptionType = [
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' },
        { label: 'Quarterly', value: 'quarterly' },
        { label: 'Annual', value: 'annual' },
        { label: 'Specific', value: 'specific' }
    ]

    const handleFrequencyChange = (value) => {
        // False and Empty all values before change the reminder type
        setEnableSelect(false);
        setEnableDatePicker(false);
        setEnableOneTimePicker(false);
        setEnableWeeklyDatePicker(false);
        setEnableYearDatePicker(false);
        setIsFieldClear(true);
        setOptionArr([
            { label: 'Quarter One', value: '4' },
            { label: 'Quarter Two', value: '3' },
            { label: 'Quarter Three', value: '2' },
            { label: 'Quarter Four', value: '1' }
        ]);

        switch (value) {
            case 'weekly':
                setEnableWeeklyDatePicker(true);
                setEnableYearDatePicker(true);
                break;
            case 'monthly':
                setEnableDatePicker(true);
                setEnableYearDatePicker(true);
                break;
            case 'quarterly':
                setEnableSelect(true);
                setEnableYearDatePicker(true);
                break;
            case 'annual':
                setEnableYearDatePicker(true);
                break;
            case 'specific':
                setEnableOneTimePicker(true);
                break;
            default:
                // console.log('Wrong type');
                break;
        }
    };

    function getDateCount(frequency, startDate) {
        const start = new Date(startDate);
        const end = new Date(new Date().getFullYear(), 11, 31); // End date: December 31st of the current year

        if (start > end) {
            notify('Start date should be after the end date', 'error');
        }

        let count;

        if (frequency === 'weekly') {
            // Calculate the number of milliseconds in one week
            const oneWeek = 7 * 24 * 60 * 60 * 1000;
            // Calculate the total weeks between start and end
            count = Math.round((end - start) / oneWeek);
        } else if (frequency === 'monthly') {
            // Calculate the total months between start and end
            count = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth()) + 1;
        } else {
            throw new Error('Invalid frequency type. Use "weekly" or "monthly".');
        }
        return count;
    }


    let handleProjectedAmount = (values, setFieldValue, frequencyType, frequencyStart, Amount) => {
        const { amount, frequency_type, frequency_start } = values;
        let projectedAmount = 0;
        let current_amount = Amount ? Amount : amount;
        let frequencyStartValue = frequencyStart ? frequencyStart : frequency_start;
        let frequencyTypeValue = frequencyType ? frequencyType : frequency_type;
        let startDate = '';
        if (editMode) {
            startDate = currentYear == initialValues.created_year ? frequencyStartValue : currentYear.toString().concat('-01-01')
        } else {
            startDate = frequencyStartValue;
        }
        if (initialValues.year_upto && (currentYear > initialValues.year_upto) && frequencyTypeValue != 'specific') {
            projectedAmount = 0;
        } else if (current_amount && frequencyTypeValue) {
            switch (frequencyTypeValue) {
                case 'monthly':
                    if (startDate) {
                        let months = getDateCount('monthly', startDate);
                        projectedAmount = current_amount * months;
                    }
                    break;
                case 'weekly':
                    if (startDate) {
                        let weeks = getDateCount('weekly', startDate);
                        projectedAmount = current_amount * weeks;
                    }
                    break;
                case 'quarterly':
                    if (startDate) {
                        projectedAmount = current_amount * startDate;
                    }
                    break;
                case 'annual':
                    console.log('Log values 21312::: ')
                    if (current_amount) {
                        projectedAmount = current_amount;
                    }
                    break;
                case 'specific':
                    let temp_transaction_date = values.transaction_date
                    if (current_amount) {
                        if (temp_transaction_date && (moment(temp_transaction_date).format('YYYY') == currentYear)) {
                            projectedAmount = current_amount
                        } else {
                            projectedAmount = 0;
                        }
                    }
                    break;
                default:
                    console.error("Unexpected frequency type:", frequency_type);
                    break;
            }
        }
        setFieldValue('projected_amount', projectedAmount);
    }


    return (
        <Modal show={show} onHide={handleClose} size="large" centered>
            <Modal.Header closeButton>
                <Modal.Title>{mode === 'view' ? `View ${headerName}` : mode === 'edit' ? `Update ${headerName}` : `Add ${headerName}`}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={investmentSchema}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    setIsSubmitting(true);
                    try {
                        let response = [];
                        const data_investmentId = editMode ? initialValues?.id : investmentId;
                        const url = editMode ? concatUrlWithId(UPDATE_INCOME_EXPENSE, investmentId) : CREATE_INCOME_EXPENSE;
                        const bodyPayload = {
                            type: keyValue,
                            amount: values.amount,
                            transaction_date: values.transaction_date,
                            comments: values.comments,
                            financial_activities: values.financial_activities,
                            investment_id: data_investmentId,
                            frequency_type: values.frequency_type,
                            frequency_start: values.frequency_start,
                            projected_amount: values.projected_amount,
                            created_year: currentYear.toString(),
                            year_upto: values.frequency_type == 'specific' ? currentYear.toString() : values.year_upto
                        };

                        response = editMode ? await put(url, bodyPayload) : await post(url, bodyPayload);

                        if (response) {
                            notify(`${keyValue} ${editMode ? 'Updated' : 'Added'} Successfully...`, 'success');

                            actions.resetForm();
                            if (isSaveNew) {
                                resetValues();
                                setEnableSelect(false);
                                setEnableDatePicker(false);
                                setEnableOneTimePicker(false);
                                setEnableWeeklyDatePicker(false);
                                setEnableYearDatePicker(false);
                                setIsFieldClear(true);
                            } else {
                                handleClose();
                            }
                        } else {
                            editMode ? notify('Income Expense update failed...', 'error') : notify('Create Income Expense failed...', 'error');
                        }
                    } catch (error) {
                        notify(error?.response?.data?.error, 'error');
                        console.error('API Error:', error);
                    }
                    setIsSubmitting(false);
                }}
            >
                {props => (
                    <>
                        <Modal.Body>
                            <div>
                                <Form>
                                    <div className='row'>
                                        {/* <legend className='text-bold'> Financial Entities </legend> */}
                                        <FormField viewMode={viewMode} fieldName="financial_activities" label="Financial Activity" value={props.values.financial_activities} maxLength={200} />
                                        <CurrencyField viewMode={viewMode} fieldName="amount" label="Amount" values={props.values} setFieldValue={(field, val, label) => {
                                            props.setFieldValue('amount', val);
                                            handleProjectedAmount(props.values, props.setFieldValue, '', '', val);
                                        }} />
                                        <div className='row'>
                                            <div className={
                                                (enableSelect || enableDatePicker || enableOneTimePicker || enableWeeklyDatePicker || enableYearDatePicker) ?
                                                    'col-lg-6  col-md-6  col-sm-6 col-6' :
                                                    'col-lg-8  col-md-8  col-sm-8 col-8'
                                            }>
                                                <SelectField viewMode={viewMode} fieldName="frequency_type" label="Frequency" value={props.values.frequency_type} options={frequencyOptionType} values={props.values}
                                                    setFieldValue={(field, val, label) => {
                                                        props.setFieldValue('frequency_type', val);
                                                        setInitialValues({ ...props.values, frequency_type: val, frequency_start: val == 'monthly' ? 0 : val == 'weekly' ? 0 : val == 'specific' ? 0 : '', projected_amount: val == 'annual' ? props.values.amount : 0, transaction_date: '' });
                                                        props.setFieldValue('frequency_start', '');
                                                        props.setFieldValue('projected_amount', '');
                                                        props.setFieldValue('transaction_date', '');
                                                        handleFrequencyChange(val);
                                                        if (val == 'annual') {
                                                            handleProjectedAmount(props.values, props.setFieldValue, val, '0', props.values.amount);
                                                        } else {
                                                            handleProjectedAmount(props.values, props.setFieldValue, val, '0', 0);
                                                        }
                                                    }} />
                                            </div>
                                            {(enableSelect || enableDatePicker || enableOneTimePicker || enableWeeklyDatePicker) ? <div className={
                                                'col-lg-6  col-md-6 col-sm-6 col-6'
                                            }>
                                                {enableSelect ? <SelectField viewMode={viewMode} fieldName="frequency_start" label="Frequency Start" value={props.values.frequency_start} options={optionArr} values={props.values}
                                                    setFieldValue={(field, val, label) => {
                                                        props.setFieldValue('frequency_start', val);
                                                        handleProjectedAmount(props.values, props.setFieldValue, '', val, '');
                                                    }} /> : ""}
                                                {enableDatePicker ? <DatePickerField viewMode={viewMode} fieldName="frequency_start" label="Frequency Start" value={props.values.frequency_start || 'MM-YYYY'} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear}
                                                    isMonth={true} handleDateFieldChange={(val) => {
                                                        props.setFieldValue('frequency_start', val);
                                                        props.setFieldValue('frequency_start_label', moment(val).format('MM-YYYY'));
                                                        handleProjectedAmount(props.values, props.setFieldValue, '', val, '');
                                                    }} isRequired={true} /> : ''}
                                                {enableWeeklyDatePicker ? <DatePickerField viewMode={viewMode} fieldName="frequency_start" label="Frequency Start" value={initialValues.frequency_start || 'MM-DD'} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear}
                                                    isDateMonth={true} handleDateFieldChange={(val) => {
                                                        props.setFieldValue('frequency_start', val);
                                                        props.setFieldValue('frequency_start_label', moment(val).format('MMM-DD'));
                                                        handleProjectedAmount(props.values, props.setFieldValue, '', val, '');
                                                    }}
                                                    isRequired={true} />
                                                    : ''}
                                                {enableOneTimePicker ? <DatePickerField viewMode={viewMode} fieldName="transaction_date" label="Transaction Date" value={initialValues.transaction_date} isFieldClear={isFieldClear}
                                                    setFieldValue={(field, val) => {
                                                        props.setFieldValue('frequency_start', val);
                                                        props.setFieldValue('transaction_date', moment(val).format('YYYY-MM-DD'));
                                                        handleProjectedAmount(props.values, props.setFieldValue, '', val, '');
                                                    }}

                                                    isDateMonth={false} handleDateFieldChange={(val) => {
                                                        props.setFieldValue('transaction_date', moment(val).format('YYYY-MM-DD'));
                                                        props.setFieldValue('frequency_start', moment(val).format('YYYY-MM-DD'));
                                                        handleProjectedAmount(props.values, props.setFieldValue, '', val, '');
                                                    }} isRequired={true} disablePastDate={true} /> : ''}
                                            </div> : ''}

                                            {enableYearDatePicker ? <div className='col-12 col-md-6'>
                                                <DatePickerField viewMode={viewMode} fieldName="year_upto" label="Year Up To" value={initialValues.year_upto || '2024'} setFieldValue={props.setFieldValue}
                                                    isFieldClear={isFieldClear}
                                                    //  disablePastDate={true}
                                                    isYear={true} handleDateFieldChange={(val) => {
                                                        props.setFieldValue('year_upto', moment(val).format('YYYY'));
                                                        // props.setFieldValue('year_upto_label', moment(val).format('YYYY'));
                                                        // handleProjectedAmount(props.values, props.setFieldValue, '', val, '');
                                                    }}
                                                    isRequired={true} />
                                               
                                            </div> : ''}
                                        </div>
                                        {/* <DatePickerField viewMode={viewMode} fieldName="year_upto" label="Year Built" value={initialValues.year_upto} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear}
                                                    isYear={true} isRequired={false} styleClass={'d-none'} /> */}
                                        <div className='row'>

                                        </div>
                                        {/* <DatePickerField viewMode={viewMode} fieldName="transaction_date" label="Transaction Date" value={props.values.transaction_date} setFieldValue={props.setFieldValue} isFieldClear={isFieldClear} /> */}
                                        {/* <CurrencyField viewMode={viewMode} fieldName="projected_amount" label="Projected Amount" values={props.values} setFieldValue={props.setFieldValue} disabled={true}/> */}
                                        {/* {(enableSelect || enableDatePicker || enableWeeklyDatePicker) ? */}
                                        <div style={{ padding: '5px 20px 17px 14px', fontSize: '12px' }} >Projected Amount will<span style={{ fontWeight: 'bold', color: 'black' }} > ${(Number(props.values.projected_amount) || 0).toFixed(2)} </span> {(enableSelect || enableDatePicker || enableWeeklyDatePicker) ? `for this year ${currentYear}` : ''}</div>
                                        {/* : ''} */}
                                        <TextAreaField viewMode={viewMode} fieldName="comments" label="Comments" value={props.values.comments} maxLength={200} />
                                    </div>
                                </Form>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="reset" variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            {!viewMode && <Button type="submit" variant="primary" disabled={isSubmitting} onClick={() => { props.submitForm(); setIsSaveNew(false); }} className="btn btn-primary w-md">
                                {editMode ? 'Update' : 'Save'}
                            </Button>}
                            {editMode !== true && (!viewMode && <Button type="submit" onClick={() => { props.submitForm(); setIsSaveNew(true); }} className="btn btn-primary w-md">{editMode ? 'create' : 'Save and New'}</Button>)}
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>
    );


}
export default IncomeExpenseModelForm;