import { useState, useContext, useEffect } from 'react';
import Footer from "../../../components/Footer";
import { PageLoader } from '../../../Context/PageLoader';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Datatable from '../../../components/Table/Datatable';
import DataChart from '../../../components/DataChart';
import { get, put, remove } from '../../../api';
import { GET_PERSONAL_ASSETS, DELETE_INVESTMENT_BY_ID, ARCHIVE_INVESTMENT_BY_ID } from '../../../api/endpoints'
import { checkPermission, concatUrlWithId, notify, refreshCount } from '../../../utils/commonUtils';
import { UserPermissionStore } from '../../../Store/UserPermission';
import ReminderTab from '../../../components/Reminder/ReminderTab';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function PersonalAssetsList() {
  const [personalAssetsList, setPersonalAssetsList] = useState([]);
  const [selectedInvList, setSelectedInvList] = useState([]);
  const [selectedChartValue, setSelectedChartValue] = useState("");
  const [userPermissions, setUserPermissions] = useState("");
  const [showReminderModel, setShowReminderModel] = useState(false);
  const [selectedInvID, SetSelectedInvID] = useState('')
  const { setLoading } = useContext(PageLoader);
  const history = useNavigate();
  const { checkAndUpdatePermissions } = UserPermissionStore();
  const [showAction, setShowAction] = useState(false);
  const [reminderActionPermission, setReminderActionPermission] = useState(false);
  const category_id = process.env.REACT_APP_PERSONAL_ASSETS_MASTER_ID;
  const [enablePrint, SetEnablePrint] = useState(false)

   // Reminder Task 
   const [showReminderTask, setShowReminderTask] = useState(false);
   const [getInvestmentID, setInvestmentID] = useState('');
   const reminderTaskPopUp = (investment_id, actionPermission) => {
     setInvestmentID(investment_id);
     setShowReminderTask(true);
     setReminderActionPermission(actionPermission)
   };
 
   const handleClose = () => setShowReminderTask(false);
   
   const handelListUpdate = () => fetchInvestmentList();

  const fetchInvestmentList = async () => {
    try {
      const personalAssets = await get(GET_PERSONAL_ASSETS);
      if (personalAssets) {
        let isAction = personalAssets.some((inv) => inv.permission_keys?.length > 1);
        setShowAction(isAction);
        setPersonalAssetsList(personalAssets);
        setSelectedInvList(personalAssets)
      }
      refreshCount();
    } catch (error) {
      console.log('Error while throughing:: ', error);
      notify(`Failed to fetch.`, 'error');
    } finally {
      setLoading(false);
    }
  }

  const authCheckAndFetch = async () => {
    let tempPermission = await checkAndUpdatePermissions();
    setUserPermissions(tempPermission || []);
    if (checkPermission(tempPermission, 'view-personal-asset') || checkPermission(tempPermission, 'isAdmin') || checkPermission(tempPermission, 'isManager')) {
      fetchInvestmentList()
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true)
    authCheckAndFetch();
  }, [])

  const handleChartChange = (selectedValue) => {
    if (selectedChartValue == selectedValue) {
      setSelectedInvList(personalAssetsList)
      setSelectedChartValue("")
    } else {
      let filterInvesmentList = personalAssetsList.filter((item) => (selectedValue == item['type']));
      setSelectedChartValue(selectedValue)
      setSelectedInvList(filterInvesmentList)
    }
  }

  const handleDelete = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      let deleteInvestment = await remove(concatUrlWithId(DELETE_INVESTMENT_BY_ID, investment_id));

      if (deleteInvestment) {
        fetchInvestmentList()
      }
      notify(`${investment_name} successfully deleted`, 'success')
    } catch (error) {
      notify(`Unable to delete ${investment_name}`, 'warning')
      console.log('Error while deleteing the error : ', error)
    }

  };

  const handleEditClick = (investmentId) => {
    setLoading(true);
    history(`/edit-personal-assets/${investmentId}`);
  };
  const handleViewClick = (investmentId) => {
    setLoading(true);
    history(`/view-personal-assets/${investmentId}`);
  };

  const AddPersonalAssets = () => {
    setLoading(true);
    history('/add-personal-assets', { state: { previousPage: "Personal Assets List", previousUrl: "/personal-assets-list" } });
  };

  const deletePersonalAssets = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Delete Personal Assets',
      message: `Are you sure you want to delete the ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDelete(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const archivedPersonalAssets = (investment_id, investment_name) => {
    confirmAlert({
      title: 'Archive Personal Assets',
      message: `Are you sure want to Archive ${investment_name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleArchived(investment_id, investment_name)
        },
        {
          label: 'No',
        }
      ]
    });
  }


  const handleArchived = async (investment_id, investment_name) => {
    try {
      setLoading(true)
      const archive = await put(concatUrlWithId(ARCHIVE_INVESTMENT_BY_ID, investment_id), { is_archived: true });
      if (archive) {
        fetchInvestmentList()
      }
      notify(`${investment_name} has been archived.`, 'warning',)
    } catch (error) {
      console.log('Error while deleteing the error : ', error)
      notify(`Failed to archive ${investment_name}.`, 'error',)
    }

  };

  const handleOnClickAction = (type, id, name, actionPermission) => {
    switch (type) {
      case 'view':
        handleViewClick(id);
        break;
      case 'edit':
        handleEditClick(id);
        break;
      case 'archive':
        archivedPersonalAssets(id, name);
        break;
      case 'delete':
        deletePersonalAssets(id, name);
        break;
      case 'reminderTask':
        reminderTaskPopUp(id, actionPermission);
        break;  
      case 'setreminder':
        handleReminderClick(id, name);
        break;
      default:
        break;
    }
  }

  const handleReminderClick = (id, name) => {
    SetSelectedInvID(id)
    setShowReminderModel(!showReminderModel)
};


const handleReminderModelClose = () => {
  setShowReminderModel(false);
};

  const columns = [
    { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
    {
      dataField: 'investment_name', text: 'Item', sort: true, isViewField: true, handleOnClick: handleOnClickAction, onClickParams: ['id'],
      hMinWidth: '12%', hMaxWidth: "15%", wordWarp: true, action: true
    },
    { dataField: 'type', text: 'Type', hMinWidth: '7%', hMaxWidth: "8%", sort: true },
    // { dataField: 'Manufacturer', text: 'Manufacturer', hMinWidth: '10%', hMaxWidth: "12%", sort: true },
    { dataField: 'current_value', text: 'Current Value($)', hMinWidth: '10%', hMaxWidth: "12%", sort: true, isAmountField: true },
    // { dataField: 'insured_value', text: 'Insured Value($)', hMinWidth: '10%', hMaxWidth: "12%", sort: true, isAmountField: true },
    // { dataField: 'insured_date', text: 'Insured Date', hMinWidth: '10%', hMaxWidth: "12%", sort: false, isDateField: true },
    // { dataField: 'performance', text: 'Performance', sort: false, isPerformanceField: true, hMinWidth: '10%', hMaxWidth: "12%" },
  ];
  let tempAction = { dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, tooltipSuffixText: "Personal Assets", hMinWidth: '10%', hMaxWidth: "12%", handleOnClick: handleOnClickAction, onClickParams: ['id', 'investment_name'], reminderCount: ['active_reminders_count'], setReminder: true }

  if (checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) {
    columns.push(tempAction);
  } else  {
    tempAction = { ...tempAction, disableDelete: true, disableEdit: true, disableArchive: true }
    columns.push(tempAction);
  }

  const handleBackClick = () => {
    history('/portfolio-summary')
  }

  const updateReminderData = async() => {
    await fetchInvestmentList();
  };

  const handleTableChange = (type, value) => {
    try {
      switch (type) {
        case  'print':
          SetEnablePrint(value);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('Error in the handle table change funciton:: ', error)
    }
  }

  const handlePrintClick = () => {
    confirmAlert({
      title: 'Alert',
      message: `For better viewing, please use landscape mode.`,
      buttons: [
        {
          label: 'Done',
          onClick: () => {SetEnablePrint(true); setLoading(true); }
        },
      ]
    }); 
  }

  return (
    <div className="main-content introduction-farm">
      <div className="col-12" style={{ padding: '5px 15px 15px 15px' }}>
        <div className="card">
        <button id="update-remainder-data" onClick={() => updateReminderData()} style={{ display: 'none' }}> Click me </button>
          <div className="card-body card-breadcrumb">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <a href="#" onClick={() => handleBackClick()} className="btn btn-light mb-2 me-2" style={{ display: 'flex', alignItems: 'center' }}><i className="arrow_left"></i>
                Back</a>
              {/* <div className="page-title-right title-prefix" >Personal Assets</div> */}
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="#" onClick={() => handleBackClick()}>Portfolio Summary
                  </a></li>
                  <li className="breadcrumb-item active" >Personal Assets</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wraper-area">
        <div className="dashboard-area">
          <div className="container-fluid">
            <DataChart
              header={"Personal Assets"} data={personalAssetsList} labelKey={'type'}
              seriesKey={["market_value", "amount_invested"]} onChange={handleChartChange}
              seriesName={["Current Valuation", "Total Purchase Price"]} type="bar"
            />
            <br />

            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title" style={{ marginBottom: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <h4 style={{ flex: 'auto' }}>Personal Assets List <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-bottom`}>Print</Tooltip>} >
                        <i className="fa fa-print btn btn-circle print-btn" onClick={() => {handlePrintClick()}} ></i>
                      </OverlayTrigger>
                    </h4>
                      {
                        (checkPermission(userPermissions, 'create-personal-asset') || checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')) &&
                        <button type="button" onClick={AddPersonalAssets} className="btn btn-primary mb-2 me-2"><i className="icon_plus"></i> Add New</button>
                      }
                    </div>
                  </div>
                  <Datatable data={selectedInvList} columns={columns} tableWidth={selectedInvList ? '110%' : '100%'} csvFileName={'PersonalAssetsList'} actionPermissionPrefix={'personal-asset'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} headerName={'Personal Assets List'} enablePrint={enablePrint} handleTableChange={handleTableChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ReminderTaskModel show={showReminderTask} investmentID={getInvestmentID} handleClose={handleClose} handelListUpdate={handelListUpdate}/>
      <ReminderList show={showReminderModel} recordId={selectedInvID}  handleClose={handleReminderModelClose} entityType={'investment'} headerName={'Reminder'} invesmentCat={category_id} handelListUpdate={handelListUpdate}/> */}
      <ReminderTab show={showReminderTask} recordId={getInvestmentID} handleClose={() => setShowReminderTask(false)} hangleTabChange={handelListUpdate} invesmentCatId={category_id} entityType={'investment'} isAdminOrManager={checkPermission(userPermissions, 'isAdmin') || checkPermission(userPermissions, 'isManager')} actionPermission={reminderActionPermission}/> 
      {(userPermissions?.length && checkPermission(userPermissions, 'isManager')) && <Footer PropUserPermissions={userPermissions} />}
    </div>);
}

export default PersonalAssetsList;