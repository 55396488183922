
import { useState, useEffect } from 'react';
import { get} from '../api'
import { GET_AUDIT_LOG_BY_INVESTMENT_ID} from '../api/endpoints'
import 'react-toastify/dist/ReactToastify.css'
import Datatable from './Table/Datatable';
import { concatUrlWithId } from '../utils/commonUtils';
import { toast } from 'react-toastify';


function AuditLogTable({ investmentId }) {
    const notify = (message, type) => toast(message, { type });
    const [documentList, setDocumentList] = useState([]);
    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        { dataField: 'updated_date', text: 'Updated Date', sort: true, isDateAndTimeField: true },
        { dataField: 'updated_user_name', text: 'Updated By', sort: true, isUserNameField: true },
        { dataField: 'field_name', text: 'Field Name', sort: true, isFieldValue: true},
        { dataField: 'original_value', text: 'Original Value', sort: true },
        { dataField: 'new_value', text: 'New Value', sort: true },
    ];
 
    // Example of calling it for the "formrow-email-input" input
    useEffect(() => {
        getAduitLog()
    }, [])

    const getAduitLog = async () => {
        try {
            let data = await get(concatUrlWithId(GET_AUDIT_LOG_BY_INVESTMENT_ID,investmentId ));
            
            setDocumentList(data)
        } catch (error) {
            console.log('Something went wrong unable to fetch Audit Log');
            // notify(error?.response?.data?.error, 'error');
            notify('Something went wrong unable to fetch Auditlog', 'error');
        }
    }

    return (<> 
        <br></br>
        <div className='row'>
            <div className='col-6'>
                <legend className='text-bold'>Audit Log</legend>
            </div>
        </div>

        <Datatable data={documentList || []} columns={columns} disableCsv={true} disableSearch={true} />
    </>)
}
export default AuditLogTable;