import { useState, useEffect } from 'react';
import { ErrorMessage } from 'formik';
import { useDropzone } from 'react-dropzone';
import { confirmAlert } from 'react-confirm-alert';
import { useLocation } from 'react-router-dom';

function ImageUpload({ handleImageChange, handleImageRemove, setFieldValue, viewMode, value, fieldName, editMode, resetImageFlag, isImageFieldClear, handleFieldClear }) {
    const [count, setCount] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileUploadStatus, setFileUploadStatus] = useState(null);
    const [imageRemove, setImageRemove] = useState(false);
    const location = useLocation();
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.png']
        },
        onDropAccepted,
        onDropRejected,
        multiple: false
    });

    function onDropAccepted(acceptedFiles) {
        const filesWithPreview = acceptedFiles.map(file => Object.assign(file, {
            objectURL: URL.createObjectURL(file)
        }));
        setSelectedFiles(prevFiles => [...prevFiles, ...filesWithPreview]);
    }

    function onDropRejected(rejectedFiles) {
        // Handle rejected files if needed
        // console.log('Rejected files:', rejectedFiles);
    }

    const handleRemoveFile = (indexToRemove) => {
          // handleImageRemove(true);
        setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));

     };
    const handleRemoveImage = () => {
        confirmAlert({
            title: 'Real Estate Property Image',
            message: `Are you sure want to delete property image?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setImageRemove(true);
                        handleImageRemove(true);
                        resetImageFlag(true);
                        // handleRemoveFile(0);
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    const files = selectedFiles.map((file, index) => (
        <li key={index}>
            <div className='file_list_container'>
                <div className='drop_image_container' style={{ maxWidth: '80px' }}><img src={file.objectURL} alt={file.name} /></div>
                <div>
                    <div className='drop_file_name'>
                        {file.name.length > 50 ? `${file.name.substring(0, 50)}...` : file.name}
                    </div>
                    <div className='upload-progress'>
                        {fileUploadStatus ? 'File uploaded...' : fileUploadStatus == null ? file.name : 'File uploading..'}
                    </div>
                </div>
                <div style={{ width: '60px' }}>  {fileUploadStatus == null && <div className='remove-drop' onClick={() => handleRemoveFile(index)}>Remove</div>}</div>
            </div>
        </li>
    ));

    useEffect(() => {
        if (isImageFieldClear) {
            handleRemoveFile(0)
            handleFieldClear(false)
         }
    }, [isImageFieldClear]);

    useEffect(() => {
        handleRemoveFile(0);
     }, [viewMode]);


    useEffect(() => {
        // if (!viewMode) {
        //     setImageRemove(false)
        // }
        if (!value) {
            setImageRemove(true)
        }
        // Cleanup function to revoke object URLs when component unmounts or when selectedFiles change
        for (const file of selectedFiles) {
            handleImageChange(file)
            setFieldValue('image', file);
            // setImageRemove(true)
        }
        return () => {
            selectedFiles.forEach(file => {
                URL.revokeObjectURL(file.objectURL);
            });
        };
    }, [selectedFiles, value, count, editMode]);

    return (<>
        {!viewMode && imageRemove && (
            <div>
                {files.length == 0 && <div {...getRootProps({ className: 'drop_area' })}>
                    <input {...getInputProps({ accept: 'image/*' })} />
                    <p>Click to select image</p>
                </div>}
                {files.length > 0 && <aside style={{ marginTop: '10px' }}>
                    <ul>{files}</ul>
                </aside>}
            </div>)}
        {viewMode && value && (
            <div className='view-label img-container'>
                <img src={value} className="centered-image" style={{ height: '350px'}}/>
            </div>
        )}
        {editMode && value && !imageRemove && (

            <div className='view-label img-container'>
                <div className="delete-button" >
                    <i className="bx bx-trash btn btn-circle" style={{ color: 'white' }} onClick={() => handleRemoveImage()}></i>
                </div>

                <img src={value} className="centered-image" style={{ height: '350px'}}/>
            </div>
        )}
        <ErrorMessage name={fieldName} component="div" className="error-message" />
    </>)
}
export default ImageUpload;