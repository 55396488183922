
import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { PageLoader } from '../../Context/PageLoader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Datatable from '../../components/Table/Datatable';
import { get, post, remove } from '../../api';
import { DELETE_REMINDER_BY_ID, GET_REMINDER_BY_INV_ID, COMMON_REMINDER_STATUS_UPDATE } from '../../api/endpoints'
import { addValueLabel, checkPermission, concatUrlWithId } from '../../utils/commonUtils';
import ReminderModelForm from '../../components/Reminder/ReminderModelForm';
import { Modal } from 'react-bootstrap';
import { dateOptions, reminderOptionType, timeOptions, weeklyOptions } from '../../Data/ReminderOptions';
import moment from "moment";
import BSForm from 'react-bootstrap/Form';



function ReminderList({ recordId, show, handleClose, mode, onSuccess, headerName, entityType, invesmentCat, actionPermission, isAdminOrManager, isTabView = false, handelListUpdate = () => { } }) {
    const [reminderList, setReminderList] = useState([]);
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [firstTime, setFirstTime] = useState(false);
    const [reminderStatus, setReminderStatus] = useState(false);
    const [modelType, setModelType] = useState('add')
    const [fieldValue, setFieldValue] = useState({});
    const { loading, setLoading } = useContext(PageLoader);
    const notify = (message, type) => toast(message, { type });

    useEffect(() => {
        setLoading(true);
        if (show && recordId) {
            fetchData();
        }else{
            setLoading(false);
        }
    }, [show, recordId])


    const fetchData = async () => {
        setFirstTime(true)
        try {
            const getReminderData = await get(concatUrlWithId(GET_REMINDER_BY_INV_ID, recordId) + '/' + entityType);
            if (getReminderData && getReminderData?.reminderList?.length) {
                let tempRemindervalue = getReminderData?.reminderList.map((rem) => {
                    rem['email_label'] = rem['email']?.length ? rem['email'].join(', ') : "";
                    rem['occurrence_type_label'] = addValueLabel(rem['occurrence_type'], reminderOptionType);
                    rem['occurrence_on_label'] = rem['occurrence_type'] == 'onetime' ? moment(rem['occurrence_on']).format("MM/DD/YYYY") :
                        rem['occurrence_type'] == 'yearly' ? moment(rem['occurrence_on']).format("MMM-DD") :
                        rem['occurrence_type'] == 'daily' ? 'Every Day' :
                            addValueLabel(rem['occurrence_on'], rem['occurrence_type'] == "weekly" ? weeklyOptions : dateOptions);
                    rem['occurrence_time_label'] = addValueLabel(rem['occurrence_time'], timeOptions);
                    return rem;
                });
                setReminderList(tempRemindervalue)
                setFirstTime(false)
            } else {
                setReminderList([]);
                setFirstTime(true)
            }
            setReminderStatus(getReminderData?.reminderStatus || false)
            setLoading(false);
        } catch (error) {
            console.log('Error in the fetch Data value ::: ', error);
            setLoading(false);
        }
    };

    const handleDelete = async (reminder_id, reminder_name) => {
        try {
            setLoading(true)
            let deleteReminder = await remove(concatUrlWithId(DELETE_REMINDER_BY_ID, reminder_id));

            if (deleteReminder) {
                // fetchInvestmentList()
            }
            notify(`${reminder_name} successfully deleted`, 'success')
            await fetchData();
            handelListUpdate(true);
            const button = document.getElementById('notification-btn-fetch');
            if (button) {
                button.click();
            }
            setLoading(false)
        } catch (error) {
            notify(`Unable to delete ${reminder_name}`, 'warning')
            console.log('Error while deleteing the error : ', error)
            setLoading(false);
        }
    };

    const handleEditClick = (reminder_id, reminder_data) => {
        setFieldValue(reminder_data);
        setModelType('edit')
        setShowReminderModel(true);
    };

    const deleteStock = (reminder_id, reminder_name) => {
        confirmAlert({
            title: 'Delete Reminder',
            message: `Are you sure you want to delete the ${reminder_name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(reminder_id, reminder_name)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const updateReminderStatus = async (status) => {
        let updateStatus = await post(COMMON_REMINDER_STATUS_UPDATE, {
            id: recordId,
            status: status,
            entity_type: entityType
        })
        setReminderStatus(status)
    }

    const handleReminderOnChange = (status) => {
        confirmAlert({
            title: 'Update Reminder Status',
            message: `Are you sure you want to ${status ? 'active' : 'deactive'} reminder?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => updateReminderStatus(status)
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const handleOnClickAction = (type, id, name, data) => {
        switch (type) {
            case 'edit':
                handleEditClick(id, data);
                break;
            case 'delete':
                deleteStock(id, name);
                break;
            default:
                break;
        }
    };

    // Reminder Function Start -------
    const AddReminder = () => {
        setShowReminderModel(true);
        setFieldValue({});
        setModelType('add');
    };

    const handleReminderModelClose = () => {
        setFieldValue({});
        setModelType('add');
        setShowReminderModel(false);
    };

    const onReminderSuccess = () => {
        if (firstTime) {
            updateReminderStatus(true);
        }
        fetchData();
    }

    const handleListClose = () => {
        setReminderList([]);
        setFirstTime(false);
        setReminderStatus(false);
        setFieldValue({})
        handleClose(false)
    }

    // Reminder Function End -------

    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        {
            dataField: 'reminder_name', text: 'Schedule Name', sort: true, isViewField: false, handleOnClick: handleOnClickAction, onClickParams: ['id'],
            headerWidth: '200px',  wordWarp: true, showDotsOnLengthExceed: true, hMinWidth: '140px'
        },
        { dataField: 'occurrence_type_label', text: 'Occurrence', sort: true },
        { dataField: 'occurrence_on_label',  text: 'Day',  headerWidth: '80px', sort: true },
        { dataField: 'occurrence_time_label', text: 'Time', headerWidth: '80px', sort: true },
        { dataField: 'email_label', text: 'Email', isEmailField: true , headerWidth: '200px',hMinWidth: '170px', hMaxWidth: '200px'}, 
    ];

    if (((reminderStatus || firstTime) && (actionPermission || isAdminOrManager) )) {
        columns.push({ dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, handleOnClick: handleOnClickAction, hideArchive: true, tooltipSuffixText: "", onClickParams: ['id', 'reminder_name'], hMinWidth: '90px' })

    }

    return (
        <>
            {!isTabView ? <>
                <Modal show={show} onHide={() => handleListClose()} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Reminder List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div>
                            <div>

                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: "1" }}>
                                    {/* <SwitchField
                                    fieldName={'record_reminder'}
                                    label="Reminder Status"
                                    viewMode={false}
                                    values={true}
                                    setFieldValue={(field, val) => {
                                        // mainProps.setFieldValue('record_reminder', val);
                                        // handleSwitchChange('record', 'record_reminder', val);
                                        // handleRecordReminderChange(val)
                                    }}
                                /> */}

                                    {/* <div className={`${'d-flex ml-2 d-flex mt-2'}`}>
                                    <label className={`form-label mr-7 `} htmlFor={`formrow-reminder-input`}>Reminder Status</label> {' '}
                                    <BSForm.Check
                                        type="switch"
                                        checked={reminderStatus}
                                        name={'reminder_name'}
                                        onChange={() => handleReminderOnChange(!reminderStatus)}
                                    />
                                </div> */}
                                </div>
                                {(actionPermission || isAdminOrManager) ? <div>

                                    <button type="button" onClick={AddReminder} className="btn btn-primary mb-2 me-2" ><i className="icon_plus"></i> Add New</button> 
                                </div> : ''}
                            </div>
                            <Datatable data={reminderList} columns={columns} csvFileName={'Liquid Asset List'} isAdminOrManager={true} disableSearch={true} />
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer style={{ justifyContent: 'center' }}>
            </Modal.Footer> */}
                </Modal>
            </> : <>
                <div className='mt-3'>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: "1" }}>
                            <div className="heading-title">
                        <h6>Reminder List &nbsp; &nbsp;<span className="board-badge">
                        </span></h6>
                    </div>
                        </div>
                        <div>
                            {(actionPermission || isAdminOrManager) ? <button type="button" onClick={AddReminder} className="btn btn-primary mb-2 me-2" ><i className="icon_plus"></i> Add New</button> : ''}
                        </div>
                    </div>
                    <Datatable data={reminderList} columns={columns} csvFileName={'Liquid Asset List'} isAdminOrManager={true} disableSearch={true} />
                </div>
            </>}
            <ReminderModelForm show={showReminderModel} recordId={recordId} mode={modelType} fieldValue={fieldValue} handleClose={handleReminderModelClose} entityType={entityType} headerName={'Reminder'} invesmentCat={invesmentCat} onSuccess={onReminderSuccess} />
        </>
    );
}

export default ReminderList;