
import { useState, useEffect } from 'react';
import { get, put } from '../../api'
import 'react-toastify/dist/ReactToastify.css'
import { concatUrlWithId } from '../../utils/commonUtils';
import { toast } from 'react-toastify';
import { GET_USER_INVESTMENTS_PERMISSION, UPDATE_USER_INVESTMENT_PERMISSION } from '../../api/endpoints';
import Datatable from '../../components/Table/Datatable';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function InvesmentPermissionTable({ userId, isManager = false}) {
    const notify = (message, type) => toast(message, { type });
    const [loading, setLoading] = useState(true)
    const [invList, setInvList] = useState([]);
    const [columns, setColumns] = useState([]);
    const [showModel, setShowModel] = useState(false);
    const [permissionList, setPermissionList] = useState([]);
    const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
    const [userSelectedPermissionIds, setUserSelectedPermissionIds] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedInvestmentId, setSelectedInvestmentId] = useState(null);
    const [selectedCat, setSelectedCat] = useState('Stock');
    const [selectedFilter, setSelectedFilter] = useState({ label: 'Liquid Assets', value: 'Stock' });

    
    const [filterOptions, setFilterOptions] = useState([
        { label: 'Liquid Assets', value: 'Stock' },
        { label: 'Private Equity', value: 'RealEstate' },
        { label: 'Real Estate Holdings', value: 'RealEstateHoldings' },
        { label: 'Royalty Income', value: 'MineralAssets' },
        { label: 'Personal Assets', value: 'PersonalAssets' },
    ]);

    useEffect(() => {
        getUserInvestementPermission('Stock')
    }, []);

    const handleOnClickAction = (type, id, name, investmentValue) => {
        const permissionIds = investmentValue.permissions.map(permission => permission.permission_id);
        const relatedPermissionIds = [...new Set(investmentValue.permissions
            .map(permission => permission.related_permissionid)
            .filter(id => id !== null))];
        setUserSelectedPermissionIds(permissionIds);
        setDisabledCheckboxes(relatedPermissionIds);
        setSelectedInvestmentId(id);
        setShowModel(true)
    }

    function removeWordsFromString(originalString) {
        let wordsToRemove = ['Investment', 'Stock', 'New', 'RealEstate', 'Holdings', 'Mineral Asset', 'Personal Asset']
        // Create a regex pattern to match the words to remove
        const regexPattern = new RegExp(wordsToRemove.join('|'), 'gi');
        // Replace occurrences of the words with an empty string, then clean up spaces
        return originalString.replace(regexPattern, '').replace(/\s+/g, ' ').trim();
    }


    const getUserInvestementPermission = async (catType) => {
        try {
            let data = await get(concatUrlWithId(GET_USER_INVESTMENTS_PERMISSION, `${catType}/${userId}`));
            // Define checkbox formatter for permissions
            const checkboxFormatter = (cell, row, rowIndex, formatExtraData) => {
                const permissionId = rowIndex;
                const hasPermission = row.permissions.some(p => p.permission_id === permissionId);
                return <input key={Math.random()} type="checkbox" className='table-disable-checkbox' checked={hasPermission} />;
            };

            // Map permissionList to columns
            const generatedColumns = data.permissionList.map(permission => ({
                dataField: permission.id,
                text: removeWordsFromString(permission.permission_name),
                formatter: (cell, row) => checkboxFormatter(cell, row, permission.id),
            }));

            // Add investment_Id and investment_name columns
            generatedColumns.unshift({
                dataField: 'investment_Id',
                text: 'Investment ID',
                csvExport: false,
                hidden: true

            }, {
                dataField: 'investment_name',
                text: 'Name',
                sort: true,
                isPopupView: isManager,
                handleOnClick: handleOnClickAction,
                onClickParams: ['investment_Id', 'investment_name']
            });

            setColumns(generatedColumns);
            setPermissionList(data.permissionList);
            setInvList(data.userPermission);
            setLoading(false)
        } catch (error) {
            console.log('Something went wrong unable to fetch Audit Log');
            // notify(error?.response?.data?.error, 'error');
            notify('Something went wrong unable to fetch Auditlog', 'error');
            setLoading(false)
        }
    };

    const handleClose = () => {
        setSelectedInvestmentId(null);
        setDisabledCheckboxes([]);
        setSelectedPermissions([])
        setUserSelectedPermissionIds([]);
        setShowModel(false)
    };


    const handleFilterChange = async (selectedValue, selectedLabel) => {
        // let filterInvesmentList = overviewDataList.filter((item) => (selectedValue == item['investment_categories.value']));
        setLoading(true);
        setSelectedCat(selectedValue)
        setSelectedFilter({ label: selectedLabel, value: selectedValue })
        await getUserInvestementPermission(selectedValue)
        setLoading(false);
    }


    const handleSelectAll = () => {
        const selectAllCheckbox = document.getElementById('selectAllCheckboxv1');
        const isChecked = selectAllCheckbox.checked;

        if (isChecked) {
            // If all permissions are currently selected, deselect all
            const disabledIds = [];
            const selectedPermissionsId = [];

            permissionList.forEach(permission => {
                // Check if permission has related_permissionID and add it to disabledIds
                if (permission.related_permissionID !== null && !disabledIds.includes(permission.related_permissionID)) {
                    disabledIds.push(permission.related_permissionID);
                }

                // Add permission ID to selectedPermissions
                selectedPermissionsId.push(permission.id);
            });

            setDisabledCheckboxes(disabledIds);
            setSelectedPermissions(selectedPermissionsId)
            setUserSelectedPermissionIds(selectedPermissionsId);
        } else {
            // If not all permissions are selected, select all
            setDisabledCheckboxes([]);
            setSelectedPermissions([])
            setUserSelectedPermissionIds([]);

        }
    };

    function hasRelativePermission(usrPerm, relativePermission) {
        for (let i = 0; i < permissionList.length; i++) {
            if (permissionList[i].id === usrPerm && permissionList[i].related_permissionID === relativePermission) {
                return true; // Return true if a matching main with the specified sub is found
            }
        }
        return false; // Return false if no matching main with the specified sub is found
    }

    // Test the function
    function testRelativePermission(usrPerm, relPerm) {
        for (let i = 0; i < usrPerm.length; i++) {
            let hasRelative = hasRelativePermission(usrPerm[i], relPerm);
            if (hasRelative) {
                return true; // Stop the loop and return true if a match is found
            }
        }
        return false; // Return false if no match is found
    }


    const handlePermissionChange = (permissionId, checked, userPermission) => {
        const matchID = permissionList.find(permission => permission.id === permissionId);
        let relativePermissionsId = matchID.related_permissionID;
        if (checked) {
            setSelectedPermissions(prev => [...prev, permissionId]);
            let tempUsrSelectedPermission = userSelectedPermissionIds?.length ? [...userSelectedPermissionIds, permissionId] : [permissionId];
            let tempDisabledPermission = disabledCheckboxes?.length ? [...disabledCheckboxes] : [];

            if (!tempUsrSelectedPermission.includes(relativePermissionsId) && !selectedPermissions.includes(relativePermissionsId) && relativePermissionsId !== null) {
                tempUsrSelectedPermission.push(relativePermissionsId);
            }

            if (relativePermissionsId !== null && !tempDisabledPermission.includes(relativePermissionsId)) tempDisabledPermission.push(relativePermissionsId);

            setUserSelectedPermissionIds(tempUsrSelectedPermission)
            setDisabledCheckboxes(tempDisabledPermission)

        } else {
            setSelectedPermissions(prev => prev.filter(id => id !== permissionId));
            let tempUsrSelectedPermission = userSelectedPermissionIds?.length ? [...userSelectedPermissionIds].filter(id => id !== permissionId) : [];
            let tempDisabledPermission = disabledCheckboxes?.length ? [...disabledCheckboxes] : [];

            if (tempUsrSelectedPermission.length == 0 || tempUsrSelectedPermission.length == 1) {
                if (tempUsrSelectedPermission.length == 0 && (relativePermissionsId && !selectedPermissions.includes(relativePermissionsId))) {
                    tempUsrSelectedPermission = [];
                }
                tempDisabledPermission = [];
            } else {
                let checkSameRelativeIdExistInOP = testRelativePermission(tempUsrSelectedPermission, relativePermissionsId);
                if (!checkSameRelativeIdExistInOP) {
                    tempDisabledPermission = tempDisabledPermission.filter(id => id !== relativePermissionsId)
                }
            }

            setUserSelectedPermissionIds(tempUsrSelectedPermission)
            setDisabledCheckboxes(tempDisabledPermission)

        }
    };

    const userUpdatePermission = async (values) => {
        try {
            let url = UPDATE_USER_INVESTMENT_PERMISSION;
            const investmentId = selectedInvestmentId;
            const bodyPayload = {
                UserId: userId,
                PermissionIds: userSelectedPermissionIds,
                Permission_type: selectedCat,
                InvestmentID: investmentId
            };
            const response = await put(url, bodyPayload);
            if (response) {
                notify(`Permission shared successfully`, 'success');
                handleClose();
                await getUserInvestementPermission(selectedCat);
            } else {
                notify('Permission Updated failed...', 'error');
            }
        } catch (error) {
            notify(error?.response?.data?.error, 'error');
            console.error('API Error:', error);
        }
    };

    return (<>
        <br></br>
        <div className='row'>
            <div className='col-6'>
                <legend className='text-bold'>Investment Level Permission</legend>
            </div>
        </div>

        {!loading && <Datatable data={invList || []} columns={columns || []} disableCsv={true} filterOptions={filterOptions} handleFilterChange={handleFilterChange} selectedFilter={selectedFilter} />}
        {loading && <div style={{margin: '100px'}}></div>}

        <Modal show={showModel} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton>
                <Modal.Title>Update Permission</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    selectedUser: '',
                    selectedPermissions: []
                }}
                onSubmit={(values, actions) => {
                    userUpdatePermission(values);
                }}
            >
                <Form id="form-add-todo" className="form-add-todo" style={{ padding: '20px' }}>
                    {/* {showPermission && */}
                    <div id="flaptToDo-list" className="todo-list" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <label className='todocolor'>Choose Permission</label>
                        <br />
                        <div style={{ textAlign: 'right', margin: '10px 10px 0 0' }}>
                            <label className="ckbox" style={{ cursor: 'pointer' }}>
                                <Field
                                    id="selectAllCheckboxv1"
                                    type="checkbox"
                                    name="selectAll"
                                    checked={userSelectedPermissionIds.length === permissionList.length}
                                    onChange={handleSelectAll}
                                />
                                {' '}
                                <span style={{ color: 'black' }}>Select All</span>
                            </label>
                        </div>
                        <div style={{ borderBottom: '1px solid #dcdde3', marginBottom: "5px", padding: '5px' }} ></div>
                        <div className='col-10'>
                            <div className='row p-1  border-bottom '>
                                {permissionList && permissionList.map(permission => (
                                    <div className='col-10 pt-1'>
                                        <label key={permission.id} style={{ marginTop: '8px', cursor: 'pointer' }}>
                                            <Field
                                                type="checkbox"
                                                name={`permission-item-${permission.id}`}
                                                className="permission-item form-check-input"
                                                value={permission.id}
                                                disabled={disabledCheckboxes.includes(permission.id)}
                                                checked={userSelectedPermissionIds.includes(permission.id)}
                                                onChange={(e) => {
                                                    handlePermissionChange(permission.id, e.target.checked);
                                                }}
                                            />
                                            {' '}
                                            <span style={{ color: 'black' }}>{permission.permission_name}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* {permissionList && permissionList.map(permission => (
                            <label key={permission.id} className="ckbox" style={{ marginTop: '8px', cursor: 'pointer' }}>
                                <Field
                                    type="checkbox"
                                    name={`permission-item-${permission.id}`}
                                    className="permission-item"
                                    value={permission.id}
                                    disabled={disabledCheckboxes.includes(permission.id)}
                                    checked={userSelectedPermissionIds.includes(permission.id)}
                                    onChange={(e) => {
                                        handlePermissionChange(permission.id, e.target.checked);
                                    }}
                                />
                                {' '}
                                <span style={{ color: 'black' }}>{removeWordsFromString(permission.permission_name)}</span>
                            </label>
                        ))} */}
                    </div>
                    {/* } */}
                    {/* {showPermission && */}
                    <div className="row" style={{ marginTop: '30px' }}>
                        <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                            <button type="reset" onClick={handleClose} className="btn btn-secondary w-md">Close</button>
                            <button type="submit" className="btn btn-primary w-md">Share</button>
                        </div>
                    </div>
                    {/* } */}
                </Form>
            </Formik>
        </Modal>
    </>)
}
export default InvesmentPermissionTable;