import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import { get, post, put, remove } from '../../api'
import { DOCUMENT_UPLOAD, DOCUMENT_GET_BY_ID, YEAR_DOCUMENT_UPLOAD, GET_YEAR_DOCUMENT_BY_ID } from '../../api/endpoints'
import { FormField, SelectField } from '../Forms/FormFields';
import { Formik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { notify } from '../../utils/commonUtils';
import { DocumentTypeStore } from '../../Store/UserPermission';


function Uploader({ show, handleClose, mode, editId, onSuccess, investmentId = '', isOperatingBusiness = false, oBYearId = '', businessID = '', documentTypeKey = '', }) {

    const [initialValues, setInitialValues] = useState({ document_name: '', document_type: '', document_location_url: '', file_versions: [], extension: '', other: '' });

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileUploadStatus, setFileUploadStatus] = useState(null);
    const [noFileError, setNoFileError] = useState(false)
    const { checkAndUpdateDocumentTypes } = DocumentTypeStore();
    const [documentTypeValue, setDocumentTypeValue] = useState({});
    const [selectedDocType, setSelectedDocType] = useState("");


    const { getRootProps, getInputProps } = useDropzone({
        // accept: '.pdf, image/*',// Define the file types you want to accept
        accept: {
            'image/*': ['.jpeg', '.png', '.jpg', '.pdf', '.csv', '.docx'],
        },
        onDropAccepted,
        onDropRejected,
        multiple: false
    });


    function onDropAccepted(acceptedFiles) {
        const filesWithPreview = acceptedFiles.map(file => Object.assign(file, {
            objectURL: URL.createObjectURL(file)
        }));
        setSelectedFiles(prevFiles => [...prevFiles, ...filesWithPreview]);
    }

    function onDropRejected(rejectedFiles) {
        // Handle rejected files if needed
        // console.log('Rejected files:', rejectedFiles);
    }

    const handleRemoveFile = (indexToRemove) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    };

    const handleDocType = async (val) => {
        setSelectedDocType(val);
    };


    const handleUpload = async (values) => {
         if (mode == "edit") {

            setFileUploadStatus(false)
            // Iterate over selected files

            const formData = new FormData();
            formData.append('file', selectedFiles[0]);
            formData.append('document_type', values.document_type)
            formData.append('document_name', values.document_name)
            formData.append('id', editId)
            formData.append('other', selectedDocType == "Other" ?  values.other : "")

            if (isOperatingBusiness) {
                formData.append('year_id', businessID);
                formData.append('business_id', businessID);
            }
            // Send files to server using Axios POST request
            const response = await put(isOperatingBusiness ? YEAR_DOCUMENT_UPLOAD : DOCUMENT_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setFileUploadStatus(true)
            onSuccess(true, values.document_name)

        } else {
            if (selectedFiles.length > 0) {
                setNoFileError(false)
                try {
                    setFileUploadStatus(false)
                    // Iterate over selected files
                    const formData = new FormData();
                    formData.append('file', selectedFiles[0]);
                    formData.append('document_type', values.document_type)
                    formData.append('document_name', values.document_name)
                    formData.append('other', selectedDocType == "Other" ?  values.other : "")

                    if (isOperatingBusiness) {
                        formData.append('year_id', businessID);
                        formData.append('business_id', businessID);
                    } else {
                        formData.append('investment_id', investmentId)
                    }
                    // Send files to server using Axios POST request
                    const response = await post(isOperatingBusiness ? YEAR_DOCUMENT_UPLOAD : DOCUMENT_UPLOAD, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    setFileUploadStatus(true)
                    onSuccess(true, values.document_name)
                } catch (error) {
                    // Handle error if upload fails
                    console.error('Error uploading files:', error);
                    notify(`Failed to upload. Please ensure that the file format is supported and try again later.`, 'error')
                }
            } else {
                setNoFileError(true)
            }
        }
    };


    const files = selectedFiles.map((file, index) => (
        <li key={index}>
            <div className='file_list_container'>
                <div className='drop_image_container' style={{ maxWidth: '80px' }}><img src={file.objectURL} alt={file.name} /></div>
                <div>
                    <div className='drop_file_name'>
                        {file.name.length > 50 ? `${file.name.substring(0, 50)}...` : file.name}
                    </div>
                    <div className='upload-progress'>
                        {fileUploadStatus ? 'File uploaded...' : fileUploadStatus == null ? 'Ready to upload.' : 'File uploading..'}
                    </div>
                </div>
                <div style={{ width: '60px' }}>  {fileUploadStatus == null && <div className='remove-drop' onClick={() => handleRemoveFile(index)}>Remove</div>}</div>
            </div>
        </li>
    ));

    useEffect(() => {
        // Cleanup function to revoke object URLs when component unmounts or when selectedFiles change
        return () => {
            selectedFiles.forEach(file => {
                URL.revokeObjectURL(file.objectURL);
            });
        };
    }, [selectedFiles]);

    useEffect(() => {
        let documentKeyValues = {};
        if (documentTypeKey != '') {
            checkAndUpdateDocumentTypes().then(documentTypesList => {
                documentKeyValues = documentTypesList[documentTypeKey]; 
                setDocumentTypeValue(documentKeyValues)
            });
        }

    }, [investmentId])

 

    const documentTypes =  Object.keys(documentTypeValue).length != 0 ? documentTypeValue :
    [{ label: 'Deed', value: 'Deed' }, { label: 'Operating Agreement', value: 'Operating Agreement' }, { label: 'Rental Agreement', value: 'Rental Agreement' }, { label: 'E&I Doc', value: 'E&I Doc' }];

    const uploadDocsSchema = Yup.object().shape({
        document_name: Yup.string()
            .label('Document Name').trim().required('Document Name is required'),
        document_type: Yup.string()
            .label('Document Type').trim().required('Document Type is required'),
        other: Yup.string().trim().when('document_type', {
                is:"Other", // If document_type is 'other'
                then: (scheme) => scheme.required('Other description is required'),
                otherwise: (scheme) => scheme.notRequired()
            })
    });
   

    const fetchData = async () => {
        if (mode === 'edit' && editId !== '') {
            try {
                const editData = await get(isOperatingBusiness ? GET_YEAR_DOCUMENT_BY_ID : DOCUMENT_GET_BY_ID, { id: editId });
                // Process the fetched data here
                setSelectedDocType(editData?.data?.document_type || "")

                setInitialValues({
                    document_name: editData?.data?.document_name,
                    document_type: editData?.data?.document_type,
                    document_location_url: editData?.data?.document_location_url,
                    extension: editData?.data?.extension,
                    other: editData?.data?.other || ""
                })
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('Error fetching data:', error);
                notify(`Failed to data.`, 'error')
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [mode, editId]);

    useEffect(() => {
        setFileUploadStatus(null);
        setSelectedFiles([])
        if (mode != "edit" && show) {
            setInitialValues({
                document_name: '',
                document_type: '',
                document_location_url: '',
                extension: '',
                other : '',
            })
            setSelectedDocType("")

        } else {
            fetchData();
        }


    }, [show])

    return (
        <Modal show={show} onHide={handleClose} size="medium" centered>
            <Modal.Header closeButton>
                <Modal.Title>{mode == "edit" ? "Update Document Details" : "Upload Documents"}</Modal.Title>
            </Modal.Header>
            <Formik

                initialValues={initialValues}
                validationSchema={uploadDocsSchema}
                onSubmit={async (values, actions) => { handleUpload(values) }}
                enableReinitialize
            >
                {props => (
                    <>
                        <Modal.Body>
                            <div>
                                <SelectField options={documentTypes} fieldName='document_type' label="Document Type" value={initialValues.document_type} values={props.values} setFieldValue={(field, val, label) => {
                                    props.setFieldValue('document_type', val);
                                    handleDocType(label);
                                }} />
                                {(selectedDocType === "Other") && <FormField fieldName="other" label="Other" value={initialValues.other} maxLength={100} type="text" />}

                                <FormField fieldName='document_name' label={'Document Name'} />
                                {files.length == 0 && <div {...getRootProps({ className: 'drop_area' })}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>}
                                {noFileError && <span style={{ color: 'red', fontSize: '12px' }}> Select file for upload </span>}
                                {files.length > 0 && <aside style={{ marginTop: '10px' }}>
                                    <ul>{files}</ul>
                                </aside>}
                                <img
                                    src={
                                        initialValues.extension === 'pdf' ? '../img/pdf-download.png' :
                                            initialValues.extension === 'csv' ? '../img/csv-download.png' :
                                                initialValues.extension === 'file' ? '../img/file-download.jpeg' :
                                                    initialValues?.extension === 'docx' ? '../img/docs-download.png' :
                                                        initialValues.document_location_url
                                    } width={initialValues.extension === 'pdf' ? '30%' : initialValues.extension === 'csv' ? '30%' : initialValues?.extension === 'docx' ? '15%' : '100%'} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="reset" variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button disabled={fileUploadStatus == false} type="submit" onClick={() => props.submitForm()} variant="primary">
                                {mode == "edit" ? "Update" : "Upload"} {fileUploadStatus == false && <Spinner animation="border" size="sm" />}
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>)
}
export default Uploader;