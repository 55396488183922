import { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import { get, post, put } from "../../api";
import { concatUrlWithId, notify } from "../../utils/commonUtils";
import { CREATE_OPERATING_BUSINESS, GET_OPERATING_BUSINESS, UPDATE_OPERATING_BUSINESS } from "../../api/endpoints";
import { FormField, SelectField } from "../../components/Forms/FormFields";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { PageLoader } from "../../Context/PageLoader";



const OperatingBusinessModel = ({ mode, show, handleClose, businessID = null, handleChange = null, handleBusinessModel = () => {} }) => {
    const [fileUploadStatus, setFileUploadStatus] = useState(null);
    const history = useNavigate();
    const { loading, setLoading } = useContext(PageLoader);
    const [initialValues, setInitialValues] = useState({ business_name: '', primary_product_service: '', operating_business_id: '', status: '' });
    const [isSaveandNext, setSaveAndNext] = useState(false);
    const [showModel, setShowModel] = useState(false);

    useEffect(() => {
        setShowModel(show)
        if (mode == 'edit' && show) {
            handleShowViewModal(businessID);
        }
    }, [businessID, show, mode])

    const validationSchema = Yup.object().shape({
        business_name: Yup.string()
            .label('Business Name').trim().required('Business Name is required'),
        primary_product_service: Yup.string()
            .label('Primary Product/Service').trim().required('Primary Product/Service is required'),
    });

    const handleShowViewModal = async (id) => {
        const editData = await get(concatUrlWithId(GET_OPERATING_BUSINESS, id));
        await setInitialValues({
            business_name: editData?.business_name,
            primary_product_service: editData?.primary_product_service,
            operating_business_id: editData?.id,
            status: editData?.status
        })
    }


    const handleUpload = async (values) => {
        if (mode == "edit") {
            setFileUploadStatus(false)
            const payload = {
                primary_product_service: values.primary_product_service,
                business_name: values.business_name,
                id: values.operating_business_id,
                status: values.status
            }
            // Send files to server using Axios POST request
            const response = await put(UPDATE_OPERATING_BUSINESS, payload);
            if (response) {
                setShowModel(false);
                handleClose();
                viewOperatingBusiness(values.operating_business_id)
                notify(`Operating business ${values.business_name} "Updated  Successfully...`, 'success')
            }
            setFileUploadStatus(true)
        } else {

            try {
                setFileUploadStatus(false)
                const payload = {
                    primary_product_service: values.primary_product_service,
                    business_name: values.business_name
                }

                // Send files to server using Axios POST request
                const response = await post(CREATE_OPERATING_BUSINESS, payload);
                if (response && response.business && response.business?.id) {
                    setShowModel(false);
                    notify(`Operating business ${values.business_name} "Created  Successfully...`, 'success')
                    if (isSaveandNext) {
                        viewOperatingBusiness(response.business?.id, true)
                    }
                }
                setFileUploadStatus(true)
            } catch (error) {
                // Handle error if upload fails
                console.error('Error uploading files:', error);
                notify(`Failed to upload. Please ensure that the file format is supported and try again later.`, 'error')
            }
        }
    };

    const viewOperatingBusiness = async (id) => {
        history(`/operating-business-view/${id}`,  { state: { isOpenModel: true } });
    }

    const statusType = [
        { value: true, label: 'Active' },
        { value: false, label: 'Deactivate' },
    ];

    return (
        <Modal show={showModel} onHide={handleClose} centered >
            <Modal.Header closeButton>
                <Modal.Title>{mode == "edit" ? "Update " : "Add"} Operating Business</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => { handleUpload(values) }}
                    enableReinitialize
                >
                    {props => (
                        <>
                            <Modal.Body>
                                <div>
                                    <FormField fieldName="business_name" label="Business Name" value={initialValues.business_name} maxLength={200} values={props.values} setFieldValue={props.setFieldValue} isRequired={true} />
                                    <FormField fieldName="primary_product_service" label="Primary Product/Service" value={initialValues.primary_product_service} values={props.values} setFieldValue={props.setFieldValue} maxLength={200} isRequired={true} />
                                    {mode == 'edit' && <SelectField fieldName="status" label="Status" value={initialValues.status} options={statusType} values={props.values} setFieldValue={props.setFieldValue} />}

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="reset" variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                {(mode === 'add') && <Button type="submit" variant="primary" disabled={fileUploadStatus == false} onClick={() => { props.submitForm() }} className="btn btn-primary w-md">
                                    {(mode === "edit") ? 'Update and Close' : 'Save and Close'}
                                </Button>}
                                {<Button type="submit" onClick={() => { props.submitForm(); setSaveAndNext(true) }} className="btn btn-primary w-md"> {mode == "edit" ? "Update" : "Save and Next"}
                                    {fileUploadStatus == false && <Spinner animation="border" size="sm" />}
                                </Button>}
                            </Modal.Footer>
                        </>
                    )}
                </Formik>
            </Modal.Body>
        </Modal >
    );
}

export default OperatingBusinessModel;

