export const reminderOptionType = [
    { label: 'One Time', value: 'onetime' },
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Yearly', value: 'yearly' }
]

export const weeklyOptions = [
    { value: '0', label: 'Sunday' }, // Sunday
    { value: '1', label: 'Monday' }, // Monday
    { value: '2', label: 'Tuesday' }, // Tuesday
    { value: '3', label: 'Wednesday' }, // Wednesday
    { value: '4', label: 'Thursday' }, // Thursday
    { value: '5', label: 'Friday' }, // Friday
    { value: '6', label: 'Saturday' } // Saturday
];

export const dateOptions = [
    { value: '1', label: '1st' },
    { value: '2', label: '2nd' },
    { value: '3', label: '3rd' },
    { value: '4', label: '4th' },
    { value: '5', label: '5th' },
    { value: '6', label: '6th' },
    { value: '7', label: '7th' },
    { value: '8', label: '8th' },
    { value: '9', label: '9th' },
    { value: '10', label: '10th' },
    { value: '11', label: '11th' },
    { value: '12', label: '12th' },
    { value: '13', label: '13th' },
    { value: '14', label: '14th' },
    { value: '15', label: '15th' },
    { value: '16', label: '16th' },
    { value: '17', label: '17th' },
    { value: '18', label: '18th' },
    { value: '19', label: '19th' },
    { value: '20', label: '20th' },
    { value: '21', label: '21st' },
    { value: '22', label: '22nd' },
    { value: '23', label: '23rd' },
    { value: '24', label: '24th' },
    { value: '25', label: '25th' },
    { value: '26', label: '26th' },
    { value: '27', label: '27th' },
    { value: '28', label: '28th' },
    { value: '29', label: '29th' },
    { value: '30', label: '30th' },
    { value: '31', label: '31st' }
];

export const timeOptions = [
    { label: '12:00 AM', value: '00:00' },
    { label: '01:00 AM', value: '01:00' },
    { label: '02:00 AM', value: '02:00' },
    { label: '03:00 AM', value: '03:00' },
    { label: '04:00 AM', value: '04:00' },
    { label: '05:00 AM', value: '05:00' },
    { label: '06:00 AM', value: '06:00' },
    { label: '07:00 AM', value: '07:00' },
    { label: '08:00 AM', value: '08:00' },
    { label: '09:00 AM', value: '09:00' },
    { label: '10:00 AM', value: '10:00' },
    { label: '11:00 AM', value: '11:00' },
    { label: '12:00 PM', value: '12:00' },
    { label: '01:00 PM', value: '13:00' },
    { label: '02:00 PM', value: '14:00' },
    { label: '03:00 PM', value: '15:00' },
    { label: '04:00 PM', value: '16:00' },
    { label: '05:00 PM', value: '17:00' },
    { label: '06:00 PM', value: '18:00' },
    { label: '07:00 PM', value: '19:00' },
    { label: '08:00 PM', value: '20:00' },
    { label: '09:00 PM', value: '21:00' },
    { label: '10:00 PM', value: '22:00' },
    { label: '11:00 PM', value: '23:00' },
];

export const monthlyOptions = Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: `${i + 1}st` }));
export const quarterlyOptions = Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: `${i + 1}st` }));