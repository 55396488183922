import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { get, post, put, remove } from '../../api'
import { PROFILE_PHOTO_UPLOAD } from '../../api/endpoints'
import { notify } from '../../utils/commonUtils';

function ProfileUpload({showUploadPopup, handleClosePopup}) {

    const [initialValues, setInitialValues] = useState({ document_type: '', document_name: '' });

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileUploadStatus, setFileUploadStatus] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.png']
          },
        onDropAccepted,
        onDropRejected,
        multiple: false
    });
    useEffect(() => {
    if(showUploadPopup){
        setShow(true);
    }
    },[showUploadPopup]);
    function onDropAccepted(acceptedFiles) {
        const filesWithPreview = acceptedFiles.map(file => Object.assign(file, {
            objectURL: URL.createObjectURL(file)
        }));
        setSelectedFiles(prevFiles => [...prevFiles, ...filesWithPreview]);
    }

    function onDropRejected(rejectedFiles) {
        // Handle rejected files if needed
        console.log('Rejected files:', rejectedFiles);
    }

    const handleRemoveFile = (indexToRemove) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    };


    const handleUpload = async () => {
        try {
            setFileUploadStatus(false)
            setIsUploading(true);
            // Iterate over selected files
            for (const file of selectedFiles) {
                // Create form data
                const formData = new FormData();
                formData.append('file', file);

                // Send files to server using Axios POST request
                const response = await post(PROFILE_PHOTO_UPLOAD, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                    
                });
                if(response){
                    if(response.location) localStorage.setItem('profileImageUrl', JSON.stringify(response.location || ''));
                    handleClosePopup(false);
                }
                setFileUploadStatus(true)
                setIsUploading(false);
            }
        } catch (error) {
            // Handle error if upload fails
            notify(`Failed to upload. Please ensure that the file format is supported and try again later.`, 'error')
            console.error('Error uploading files:', error);
            setIsUploading(false);
        }
    };


    const files = selectedFiles.map((file, index) => (
        <li key={index}>
            <div className='file_list_container'>
                <div className='drop_image_container' style={{ maxWidth: '80px' }}><img src={file.objectURL} alt={file.name} /></div>
                <div>
                    <div className='upload-progress'>
                        {fileUploadStatus ? 'File uploaded...' : fileUploadStatus == null ? 'Ready to upload.' : 'File uploading..'}
                    </div>
                </div>
                <div style={{ width: '60px' }}>  {fileUploadStatus == null && <div className='remove-drop' onClick={() => handleRemoveFile(index)}>Remove</div>}</div>
            </div>
        </li>
    ));

    useEffect(() => {
        // Cleanup function to revoke object URLs when component unmounts or when selectedFiles change
        return () => {
            selectedFiles.forEach(file => {
                URL.revokeObjectURL(file.objectURL);
            });
        };
    }, [selectedFiles]);


    const [show, setShow] = useState(false);

    function handleClose() {
        setShow(false);
        handleClosePopup(false)
    }
    const handleShow = () => setShow(true);


    const documentTypes = [{ label: 'Deed', value: 'Deed' }, { label: 'Operating Agreement', value: 'Operating Agreement' }, { label: 'Rental Agreement', value: 'Rental Agreement' }, { label: 'E&I Doc', value: 'E&I Doc' }];

    return (<>
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px'
        }} >
        </div>

        <Modal show={show} onHide={handleClose} size="medium" centered>
            <Modal.Header closeButton>
                <Modal.Title>Upload Profile Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik

                    initialValues={initialValues}
                    onSubmit={async (values, actions) => { }}
                >
                    {props => (<div>

                        {files.length == 0 && <div {...getRootProps({ className: 'drop_area' })}>
                            <input {...getInputProps()} />
                            <p>Click here to upload your profile photo</p>
                        </div>}
                        {files.length > 0 && <aside style={{ marginTop: '10px' }}>
                            <ul>{files}</ul>
                        </aside>}


                    </div>)}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleUpload} disabled={isUploading}>
                    Upload
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}
export default ProfileUpload;