
import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { PageLoader } from '../../Context/PageLoader';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Datatable from '../../components/Table/Datatable';
import {  post } from '../../api';
import { GET_REMINDER_TASK_LIST } from '../../api/endpoints'
import { addValueLabel } from '../../utils/commonUtils';
import { dateOptions, reminderOptionType, timeOptions, weeklyOptions } from '../../Data/ReminderOptions';
import moment from "moment";



function ReminderTabList({ recordId, entityType, reminderData = [] }) {
    const [reminderList, setReminderList] = useState([]);
    const { loading, setLoading } = useContext(PageLoader);
    const notify = (message, type) => toast(message, { type });

    useEffect(() => {
        if (recordId) {
            if(reminderData.length){
                setReminderList(reminderData)
            }else{
                fetchData();
            }
        }
    }, [recordId])


    const fetchData = async () => {
        const getReminderData = await post(GET_REMINDER_TASK_LIST, {record_id: recordId, status: ['completed'] });
        if (getReminderData && getReminderData?.reminderList?.length) {
            let tempRemindervalue = getReminderData?.reminderList.map((rem) => {
                rem['reminder_name'] = rem?.reminder_data['reminder_name'];
                rem['email_label'] = rem?.reminder_data?.['email']?.length ? rem.reminder_data['email'].join(', ') : "";
                rem['occurrence_type_label'] = addValueLabel(rem.reminder_data['occurrence_type'], reminderOptionType);
                rem['occurrence_on_label'] = rem.reminder_data['occurrence_type'] == 'onetime' ? moment(rem.reminder_data['occurrence_on']).format("MM/DD/YYYY") :
                    rem['occurrence_type'] == 'yearly' ? moment(rem['occurrence_on']).format("MMM-DD") :
                    rem.reminder_data['occurrence_type'] == "daily" ? 'Every Day' :
                    addValueLabel(rem.reminder_data['occurrence_on'], rem.reminder_data['occurrence_type'] == "weekly" ? weeklyOptions : dateOptions);
                rem['occurrence_time_label'] = addValueLabel(rem.reminder_data['occurrence_time'], timeOptions);
                rem['completedBy'] = rem?.user_reminder_data?.full_name || '';
                return rem;
            });
            setReminderList(tempRemindervalue)
        } else {
            setReminderList([]);
        }
    };


    // Reminder Function End -------

    const columns = [
        { dataField: 'id', text: 'ID', csvExport: false, hidden: true },
        {
            dataField: 'reminder_name', text: 'Schedule Name', sort: true, isViewField: false, 
            headerWidth: '40%', wordWarp: true
        },
        // { dataField: 'occurrence_type_label', text: 'Occurrence', sort: true },
        // { dataField: 'occurrence_on_label', text: 'On', sort: true },
        // { dataField: 'occurrence_time_label', text: 'Time', sort: true },
        // { dataField: 'email_label', text: 'Email' },
        { dataField: 'reminder_date', text: 'Due Date', isDateField: true, headerWidth: '15%' },
        { dataField: 'completedBy', text: 'Completed By' , headerWidth: '20%'},
        { dataField: 'updatedAt', text: 'Completed On', isDateAndTimeField: true , headerWidth: '25%' },

    ];

    // if ((reminderStatus || firstTime)) {
    //     columns.push({ dataField: 'Action', text: 'Action', csvExport: false, standardAction: true, handleOnClick: handleOnClickAction, hideArchive: true, tooltipSuffixText: "", onClickParams: ['id', 'reminder_name'] })
    // }

    return (
        <>
            <br></br>
            <div className='row'>
                <div className='col-6'>
                    <legend className='text-bold'>Completed Reminders</legend>
                </div>
            </div>
            <Datatable data={reminderList} columns={columns} csvFileName={'Liquid Asset List'} isAdminOrManager={true} disableSearch={true} />
        </>
    );
}

export default ReminderTabList;