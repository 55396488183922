import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormField, SelectField } from './Forms/FormFields';
import { ActionIcon } from '../utils/commonUtils';
import * as Yup from 'yup';
function ProfessionalContacts({ viewAction = false, contactList = [], CollectedContact }) {
    const [showViewModal, setShowViewModal] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [viewMode, setViewMode] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentContact, setCurrentContact] = useState(null);
    const [editIndex, setEditIndex] = useState(-1);

    useEffect(() => {
        setContacts(contactList);
    }, [contactList]);

    const initialValues = {
        contact_type: '',
        name: '',
        phone_number: '',
        email: ''
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setCurrentContact(null);
        setEditIndex(-1);
    };

    const handleShowViewModal = (contact = null, index = -1) => {
        if (contact) {
            setCurrentContact(contact);
            setEditIndex(index);
        } else {
            setCurrentContact(initialValues);
            setEditIndex(-1);
        }
        setShowViewModal(true);
    };

    const documentTypes = [
        { label: 'Accountant', value: 'Accountant' },
        { label: 'Attorney', value: 'Attorney' },
        { label: 'Banker', value: 'Banker' },
        { label: 'Property Manager', value: 'Property Manager' },
        { label: 'Insurance Contact', value: 'Insurance Contact' },
    ];

    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return '';
        const formattedValue = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        return formattedValue;
    };

    const handleSubmit = (values, actions) => {
        let updatedContacts = [];
        if (editIndex === -1) {
            updatedContacts = [...contacts, values];
        } else {
            updatedContacts = [...contacts];
            updatedContacts[editIndex] = values;
        }
        setContacts(updatedContacts);
        CollectedContact(updatedContacts);
        actions.resetForm();
        handleCloseViewModal();
    };
    const strictEmailRegex = /^[^\s@.]+(\.[^\s@.]+)*@[^\s@.]+(\.[^\s@.]+)+$/ ;

    const contactValidationSchema = Yup.object().shape({
        name: Yup.string()
            .label('Name').trim().required('Name is required'),
        contact_type: Yup.string()
            .label('Contact Type').trim().required('Contact Type is required'),
        email: Yup.string()
                .label('Email').email('Invalid email address')
                .matches(strictEmailRegex, 'Invalid email address')
                .required('Email is required'),
        phone_number: Yup.string().label('Phone Number').trim().required('Phone Number is required').matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid mobile number'),

    });

    return (
        <>
            {!viewAction && <div className='row'>
                <div className='col-6'></div>
                <div className='col-6'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                        <button type="button" className="btn btn-sm btn-secondary" onClick={() => handleShowViewModal()}><i className="ti-plus me-1"></i> Add</button>
                    </div>
                </div>
            </div>}

            <Modal show={showViewModal} onHide={handleCloseViewModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editIndex === -1 ? 'Add' : 'Edit'} Contact</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={currentContact || initialValues}
                    validationSchema={contactValidationSchema}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {props => (
                        <>
                            <Modal.Body>
                                <Form>
                                    <div className='row'>
                                        <SelectField options={documentTypes} fieldName='contact_type' label="Contact Type" values={props.values} setFieldValue={props.setFieldValue} />
                                        <FormField viewMode={viewMode} fieldName="name" label="Name" value={props.values.name} maxLength={100} />
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="formrow-holding-company-input">Phone Number{!viewMode ? <span className='man-start'>*</span> : ''}</label>
                                            {!viewMode && (
                                                <Field className="form-control" maxLength={14} readOnly={viewMode}
                                                    type="tel" id="formrow-holding-company-input" name="phone_number"
                                                    onInput={(e) => {
                                                        const inputValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                                        const formattedValue = inputValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                                        e.target.value = formattedValue;
                                                    }}
                                                />
                                            )}
                                            {viewMode && <div className='view-label'>{formatPhoneNumber(props.values.phone)}</div>}
                                            <ErrorMessage name="phone_number" component="div" className="error-message" />
                                        </div>
                                        <FormField type='email' viewMode={viewMode} fieldName="email" label="Email" value={props.values.email} maxLength={200} />
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="reset" variant="secondary" onClick={handleCloseViewModal}>
                                    Cancel
                                </Button>
                                {!viewMode && <Button type="submit" variant="primary" disabled={isSubmitting} onClick={props.submitForm} className="btn btn-primary w-md">
                                    Save
                                </Button>}
                            </Modal.Footer>
                        </>
                    )}
                </Formik>
            </Modal>

            <div>
                <div className="table-responsive text-nowrap">
                    <table className="table table-borderless table-nowrap mb-0">
                        <thead>
                            <tr >
                                <th id='print-font-header'>Contact Type</th>
                                <th id='print-font-header'>Name</th>
                                <th id='print-font-header'>Email</th>
                                <th id='print-font-header'>Phone Number</th>
                                {!viewAction && <th>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.map((contact, index) => (
                                <tr key={index} >
                                    <td id="inv-value">{contact.contact_type}</td>
                                    <td id="inv-value">{contact.name}</td>
                                    <td id="inv-value">{contact.email}</td>
                                    <td id="inv-value">{formatPhoneNumber(contact.phone_number)}</td>
                                    {!viewAction && <td>
                                        <ActionIcon
                                            iconClass="bx bxs-edit"
                                            tooltip={`Edit`}
                                            onClick={() => handleShowViewModal(contact, index)}
                                            backgroundColor="#58ae25"
                                        />
                                        <ActionIcon
                                            iconClass="bx bx-trash"
                                            tooltip={`Delete`}
                                            onClick={() => {
                                                const newContacts = contacts.filter((_, i) => i !== index);
                                                setContacts(newContacts);
                                                CollectedContact(newContacts);
                                            }}
                                            backgroundColor={"#dd4a57"}
                                        />
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default ProfessionalContacts;
